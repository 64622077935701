import React, { useEffect, useRef, useState } from 'react';

// Declare the global WirisPlugin object that will be loaded by the script
declare global {
  interface Window {
    WirisPlugin: any;
  }
}

const MathTypeEditor: React.FC = () => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [mathMLContent, setMathMLContent] = useState<string>(''); // State to hold MathML content

  // Load the Wiris plugin script when the component mounts
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/node_modules/@wiris/mathtype-generic/wirisplugin-generic.js';
    script.async = true;

    script.onload = () => {
      initializeMathType();
    };

    script.onerror = () => {
      console.error('Failed to load Wiris plugin.');
    };

    document.body.appendChild(script);
  }, []);

  // Initialize MathType editor once the script is loaded
  const initializeMathType = () => {
    if (window.WirisPlugin) {
      const genericIntegrationProperties = {
        target: editorRef.current,
        toolbar: toolbarRef.current,
      };

      const genericIntegrationInstance = new window.WirisPlugin.GenericIntegration(
        genericIntegrationProperties
      );
      genericIntegrationInstance.init();
      genericIntegrationInstance.listeners.fire('onTargetReady', {});

      // Observe DOM changes in the editor for both typing and equation insertions
      observeDOMChanges();

    } else {
      console.error('WirisPlugin is not available on window.');
    }
  };

  // Function to observe DOM changes in the editor
  const observeDOMChanges = () => {
    const editorNode = editorRef.current;

    if (editorNode) {
      // Create a MutationObserver to detect changes in the content
      const observer = new MutationObserver(() => {
        handleEditorChange();
      });

      // Start observing the editor for changes
      observer.observe(editorNode, {
        childList: true, // Watches for additions/removals of child nodes
        subtree: true, // Watches changes within all descendent nodes
        characterData: true // Watches text within the editor
      });
    }
  };

  // Handle changes in the editor and update MathML content
  const handleEditorChange = () => {
    if (window.WirisPlugin && editorRef.current) {
      // Extract the content in HTML format
      const htmlContent = editorRef.current.innerHTML;

      // Use WirisParser to convert HTML to MathML
      const mathML = window.WirisPlugin.Parser.endParse(htmlContent);

      // Update the MathML state
      setMathMLContent(mathML);
    } else {
      console.error('WirisPlugin is not available to parse MathML.');
    }
  };

  // Function to extract the MathML content from the editor
  const getMathContent = () => {
    if (window.WirisPlugin && editorRef.current) {
      // Extract the content in HTML format
      const htmlContent = editorRef.current.innerHTML;

      // Use WirisParser to convert HTML to MathML
      const mathMLContent = window.WirisPlugin.Parser.endParse(htmlContent);

      // Show the MathML content in an alert for now

      return mathMLContent;
    } else {
      console.error('WirisPlugin is not available to parse MathML.');
    }
  };

  // Function to set MathML content into the editor
  const setMathContent = (mathML: string) => {
    if (window.WirisPlugin && editorRef.current) {
      // Convert MathML back to HTML for the editor
      const parsedHTML = window.WirisPlugin.Parser.initParse(mathML);
      editorRef.current.innerHTML = parsedHTML;
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Wiris MathType Editor</h2>

      {/* Toolbar for the MathType editor */}
      <div ref={toolbarRef} className="toolbar mb-4"></div>

      {/* Editable area where MathType is initialized */}
      <div
        ref={editorRef}
        id="htmlEditor"
        className="w-full p-4 border border-gray-300 rounded-md mb-4"
        contentEditable="true"
      >
        Try me!
      </div>

      {/* Button to get MathML content */}
      <button
        onClick={getMathContent}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
      >
        Get Math Content (MathML)
      </button>

      {/* Example Button to set MathML content */}
      <button
        onClick={() =>
          setMathContent('<math xmlns="http://www.w3.org/1998/Math/MathML"><msqrt><mo>x</mo></msqrt></math>')
        }
        className="ml-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
      >
        Set Math Content (MathML)
      </button>

      {/* Display the MathML output below */}
      <div className="mt-6">
        <h3 className="text-xl font-semibold">MathML Output:</h3>
        <pre className="bg-gray-100 p-4 border border-gray-300 rounded-md mt-2">
          {mathMLContent || 'No MathML content yet.'}
        </pre>
      </div>
    </div>
  );
};

export default MathTypeEditor;
