import React, { useEffect, useState } from "react";
import { QuestionPickLoader } from "../../../loaders/skeletonLoader/QuestionPickLoader";
import { Tooltip } from "../../Tooltip";
import { InformationCircleIcon } from "@heroicons/react/16/solid";
import { StyledButton } from "../../Buttons/StyledButton";
import { service } from "../../../../api/service";
import { UserDataComponent } from "../../../UserData";
import { DropdownSelect } from "../../ReactSelect/DropdownSelect";

interface ChooseFromQuestionBankProps {
  qpType: string;
  questionType: any;
  partData: any;
  selectedQuestions: any[];
  setSelectedQuestions: (questions: any[]) => void;
  questionSelectedItems: any[];
  bulkSelect: boolean;
  availableSlots: number;
}

export const ChooseFromQuestionBank: React.FC<ChooseFromQuestionBankProps> = ({
  questionType,
  partData,
  selectedQuestions,
  setSelectedQuestions,
  questionSelectedItems,
  qpType = "manual",
  bulkSelect,
  availableSlots,
}) => {
  const [questions, setQuestions] = useState<any[]>([]);
  const [paginate, setPaginate] = useState<any>(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [examData, setExamData] = useState<any>(null);
  const { token } = UserDataComponent();
  const [selectedUnits, setSelectedUnits] = useState<any>([]);
  const [selectedSeverity, setSelectedSeverity] = useState<any>([]);
  const [unitList, setUnitList] = useState<any>([]);

  const severityList = [
    { label: "Easy", value: "easy" },
    { label: "Medium", value: "medium" },
    { label: "Hard", value: "hard" },
  ];

  const fetchQuestions = async () => {
    setPageLoader(true);
    try {
      const filters = {
        filters: {
          search: "",
          course_id: [examData?.course_data.course_uuid],
          question_type_code: [questionType],
        },
        page,
        limit: 30,
      };

      const response: any = await service.questionSearchAutoComplete(
        filters,
        token
      );
      if (response.status.code === 200) {
        const newQuestions = response.data.filter(
          (question: any) => !questionSelectedItems.includes(question.uuid)
        );
        setQuestions((prev) => [...prev, ...newQuestions]);
        setPaginate(response.paginate);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setPageLoader(false);
    }
  };

  const updateSelection = (item: any) => {
    if (bulkSelect) {
      let updatedQuestions = [...selectedQuestions];
      const isSelected = updatedQuestions.some(q => q.uuid === item.uuid);
      if (!isSelected) {
        if (updatedQuestions.length < availableSlots) {
          updatedQuestions.push(item);
        }
      } else {
        updatedQuestions = updatedQuestions.filter((q) => q.uuid !== item.uuid);
      }
      setSelectedQuestions(updatedQuestions);
    } else {
      setSelectedQuestions([item]);
    }
  };

  useEffect(() => {
    if (examData) {
      fetchQuestions();
    }
  }, [examData, questionType, page]);

  const fetchExamData = async () => {
    try {
      const examResponse: any = await service.getExamById("",
        "2e78f28b-52f4-4d69-b458-7bfb3ba5934d",
        token
      ); 
      if (examResponse.status.code === 200) {
        const new_units = examResponse?.data?.unit?.map((unit: any) => ({
          label: unit?.name,
          value: unit?.id,
        }));
        setUnitList(new_units);
        setExamData(examResponse.data);
      }
    } catch (error) {
      console.error("Error fetching exam data:", error);
    }
  };

  useEffect(() => {
    fetchExamData();
  }, []);

  return (
    <div>
      <div className="flex flex-col gap-4">
        <h4 className="font-semibold uppercase">{questionType?.name}</h4>
        <div className="grid grid-cols-2 gap-2 mb-2">
          <DropdownSelect
            labelName="Select Units"
            options={unitList}
            selectedOption={selectedUnits}
            setSelectedOption={setSelectedUnits}
          />
          <DropdownSelect
            labelName="Select Severity"
            options={severityList}
            selectedOption={selectedSeverity}
            setSelectedOption={setSelectedSeverity}
          />
        </div>

        <div className="flex justify-between items-center">
          <p className="text-medium text-GreatifyGreen-600">
            {selectedQuestions.length} selected
          </p>
          {bulkSelect && (
            <p className="text-sm font-semibold">
              {availableSlots - selectedQuestions.length} slots remaining
            </p>
          )}
          {paginate && questions.length > 0 && !pageLoader && (
            <span className="text-sm font-semibold">
              Found: {paginate.total_rows}
            </span>
          )}
        </div>

        {pageLoader ? (
          <QuestionPickLoader />
        ) : (
          <>
            {questions.map((qItem, index) => {
              const isSelected = selectedQuestions.some(q => q.uuid === qItem.uuid);
              const isDisabled = bulkSelect && selectedQuestions.length >= availableSlots && !isSelected;

              return (
                <div
                  key={index}
                  className={`border rounded-lg flex justify-between py-3 px-4 mb-2 cursor-pointer ${
                    isSelected ? 'bg-blue-100' : ''
                  } ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100'}`}
                  onClick={() => !isDisabled && updateSelection(qItem)}
                >
                  <div className="flex items-center gap-3 flex-grow">
                    <input
                      type={bulkSelect ? "checkbox" : "radio"}
                      checked={isSelected}
                      value={qItem.uuid}
                      onChange={() => {}} // We'll handle changes in the parent div's onClick
                      name="question-selection"
                      disabled={isDisabled}
                      className="pointer-events-none" // Prevent clicks on the checkbox itself
                    />
                    <p className="font-semibold">{qItem.question_name}</p>
                  </div>
                  <div className="flex items-center gap-3 text-xs font-semibold">
                    Unit: {qItem.unit.name} / {qItem.severity.label}
                    {qItem.status.value === 1 && (
                      <Tooltip message={"Already used in other question papers"}>
                        <InformationCircleIcon className="w-4 h-4 text-orange-500" />
                      </Tooltip>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}

        {paginate?.next_page && (
          <div className="flex items-center justify-center">
            <StyledButton onClick={() => setPage(page + 1)}>
              Load More
            </StyledButton>
          </div>
        )}
      </div>
    </div>
  );
};