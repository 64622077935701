import { UserDataComponent } from '../../components/UserData';
import React, { createContext, useEffect, useRef, useState } from 'react'
import { callApi } from '../../api/service';

export const StudentContextProvider = createContext("");

function StudentContext({children}: {children: React.ReactNode}) {

        /* ====================== States ====================== */

        const [studentData, setStudentData] = useState<any>(null);
        const [loading, setLoading] = useState<boolean>(true);
        const { token } = UserDataComponent();
        const activeWindow = useRef(null)


        /*====================== UseEffect ======================*/

        useEffect(() => {
            getStudentStatsData();
        }, []);
    
        /*====================== API Calls ======================*/
    
        const getStudentStatsData = async () => {
            try {
                const response: any = await callApi("student_app/widget/counts/", 'GET', {}, token);
                
                setStudentData(response?.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
  return (
    <StudentContextProvider.Provider value={{ studentData, loading, activeWindow } as any}>
      {children}
    </StudentContextProvider.Provider>
  )
}

export default StudentContext