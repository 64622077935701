import { UserDataComponent } from "../UserData";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import React from "react";
import { useNavigate } from "react-router-dom";

interface BreadcrumbItem {
  label: string;
  path?: string;
}

interface BreadcrumbsProps {
  items: (string | BreadcrumbItem)[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const navigate = useNavigate();

  const handleNavigate = (item: string | BreadcrumbItem, index: number) => {
    if (typeof item === "object" && item.path) {
      navigate(item.path);
    } else {
      navigate(-(items.length - 1 - index));
    }
  };

  return (
    <ol className="flex gap-2 text-sm">
      {items.map((item, index) => (
        <li
          key={index}
          className={`flex gap-1 items-center ${
            index === items?.length - 1
              ? "text-GreatifyGreen-600"
              : "text-GreatifyNeutral-400 cursor-pointer"
          }`}
          aria-current={index === items.length - 1 ? "page" : undefined}
          onClick={() => {
            index !== items.length - 1 && handleNavigate(item, index);
          }}
        >
          {typeof item === "string" ? item : item?.label}
          {index !== items.length - 1 && (
            <ChevronRightIcon className="w-4 h-4" />
          )}
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumbs;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { ChevronRightIcon } from '@heroicons/react/20/solid';
// import { UserDataComponent } from "../../components/UserData";

// interface BreadcrumbItem {
//   label: string;
//   path?: string;
// }

// interface BreadcrumbsProps {
//   items: (string | BreadcrumbItem)[];
// }

// const BreadCrumbs: React.FC <BreadcrumbsProps> = ({ items }) => {

//   return (
//     <nav className="flex" aria-label="Breadcrumb">
//       <ol className="inline-flex items-center space-x-1 md:space-x-3">
//         {items.map((item:any, index) => {
//           const isLast = index === items.length - 1;
//           const path = `/${items.slice(0, index + 1).join('/').toLowerCase()}`;
          
//           return (
//             <li key={index} className="inline-flex items-center">
//               {index > 0 && (
//                 <ChevronRightIcon className="w-4 h-4 text-gray-400 mx-1" />
//               )}
//               {isLast ? (
//                 <span className="text-sm font-medium text-gray-500 md:ml-2">
//                   {item}
//                 </span>
//               ) : (
//                 <Link
//                   to={path}
//                   className="text-sm font-medium text-GreatifyGreen-600 hover:text-GreatifyGreen-800 md:ml-2"
//                 >
//                   {item}
//                 </Link>
//               )}
//             </li>
//           );
//         })}
//       </ol>
//     </nav>
//   );
// };

// export default BreadCrumbs;
