import { createApi, BaseQueryFn } from "@reduxjs/toolkit/query/react";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { getSubdomains } from "../utils/constants";
import { url } from "inspector";

const baseUrl = process.env.REACT_APP_API;

const axiosInstance = axios.create({
	baseURL: baseUrl,
});

const axiosBaseQuery = (): BaseQueryFn<{
	url: string;
	method: AxiosRequestConfig['method'];
	data?: AxiosRequestConfig['data'];
	// params?: AxiosRequestConfig['params'];
	token?: string;
	hasAttachment?: boolean;
}> =>
	async ({ url, method, data, token, hasAttachment }) => {
		const subdomain = getSubdomains(window.location.hostname);
		const completeBody = data;
		const headers = {
			"Content-Type": hasAttachment ? "multipart/form-data" : "application/json",
			// 'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
			...(token && { 'Authorization': `Token ${token}` }),
			...(subdomain && { 'X-Database-Choice': subdomain })
		};

		try {
			const result = await axiosInstance({
				url,
				method,
				data: completeBody,
				// params,
				headers,
			});
			return { data: result.data };
		} catch (axiosError) {
			let err = axiosError as AxiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};

const apiService = createApi({
	reducerPath: 'apiService',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		checkSubDomain: builder.mutation<ResponseType, void>({
			query: () => ({
				url: 'superadmin_app/sub_domain_check/',
				method: 'POST',
				data: '',
			}),
		}),
		login: builder.mutation<ResponseType, Record<string, string>>({
			query: (body) => ({
				url: 'user_app/login/',
				method: 'POST',
				data: body,
				hasAttachment: false,
			}),
		}),
		widgetDashboardCounts: builder.mutation<ResponseType, { role_name: string; token: string }>({
			query: ({ role_name, token }) => {
				const baseUrl = role_name === 'super-admin'
					? 'superadmin_app/dashboard_count'
					: 'admin_app/widget/counts/';
				return {
					url: baseUrl,
					method: 'GET',
					data: '',
					token,
				};
			},
		}),
		widgetQuestionPaperAlloted: builder.mutation<ResponseType, Record<string, string>>({
			query: ({ token }) => ({
				url: 'admin_app/widget/question_paper_alloted/',
				method: 'GET',
				data: '',
				token,
			}),
		}),
		widgetExamsProgress: builder.mutation<ResponseType, Record<string, string>>({
			query: ({ token }) => ({
				url: 'admin_app/widget/exam_analysis/',
				method: 'GET',
				data: '',
				token,
			}),
		}),
		widgetStudentEntrolment: builder.mutation<ResponseType, Record<string, string>>({
			query: ({ token }) => ({
				url: 'admin_app/widget/course_student_breakdown/',
				method: 'GET',
				data: '',
				token,
			}),
		}),
		getProfile: builder.mutation<ResponseType, Record<string, string>>({
			query: ({ token }) => ({
				url: 'user_app/get_self_profile/',
				method: 'GET',
				data: '',
				token,
			}),
		}),
		profileUpdate: builder.mutation<ResponseType, { data: FormData, token: string }>({
			query: ({ data, token }) => ({
				url: 'user_app/profile_self_update/',
				method: 'PUT',
				data: data,
				token,
				hasAttachment: true,
			}),
		}),
		examList: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: 'admin_app/exam/list/',
				method: 'POST',
				data: data,
				token,
			}),
		}),
		examScheduleUpdate: builder.mutation<ResponseType, { dataToPass: { exam_id: any; remarks: any; scheduled_date: string; start_time: string; }, token: string }>({
			query: ({ dataToPass, token }) => ({
				url: 'admin_app/exam/schedule/update',
				method: 'PUT',
				data: dataToPass,
				token,
			}),
		}),
		examPublishUnPublish: builder.mutation<ResponseType, { id: string, body: { status: number; }, token: string }>({
			query: ({ id, body, token }) => ({
				url: `admin_app/exam/publish/unpublish/${id}/`,
				method: 'PUT',
				data: body,
				token,
			}),
		}),
		// examPublish: builder.mutation<ResponseType, { type: string, token: string }>({
		// 	query: ({ type, token }) => ({
		// 		url: `admin_app/exam/publish/${type}/`,
		// 		method: 'PUT',
		// 		data: '', 
		// 		token,
		// 	}),
		// }),
		// examUnPublish: builder.mutation<ResponseType, { type: string, token: string }>({
		// 	query: ({ type, token }) => ({
		// 		url: `admin_app/exam/unpublish/${type}/`,
		// 		method: 'PUT',
		// 		data: '', 
		// 		token,
		// 	}),
		// }),
		getExamById: builder.mutation<ResponseType, { id: string, token: string }>({
			query: ({ id, token }) => ({
				url: `admin_app/exam_get_by_id/${id}/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		deleteExamById: builder.mutation<ResponseType, { id: string, token: string }>({
			query: ({ id, token }) => ({
				url: `admin_app/exam_del/${id}/`,
				method: 'DELETE',
				data: '',
				token,
			}),
		}),
		courseSearch: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: `admin_app/course/search/`,
				method: 'POST',
				data: data,
				token,
			}),
		}),
		courseCreate: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: `admin_app/course_save_data/`,
				method: 'POST',
				data: data,
				token,
			}),
		}),
		courseUpdate: builder.mutation<ResponseType, { id: string, data: any, token: string }>({
			query: ({ id, data, token }) => ({
				url: `admin_app/course_update_data/${id}/`,
				method: 'PUT',
				data: data,
				token,
			}),
		}),
		courseEdit: builder.mutation<ResponseType, { id: string, token: string }>({
			query: ({ id, token }) => ({
				url: `admin_app/course_get_by_id/${id}/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		courseDelete: builder.mutation<ResponseType, { id: string, token: string }>({
			query: ({ id, token }) => ({
				url: `admin_app/course_del/${id}/`,
				method: 'DELETE',
				data: '',
				token,
			}),
		}),
		courseList: builder.mutation<ResponseType, { token: string }>({
			query: ({ token }) => ({
				url: `admin_app/courses/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		staffList: builder.mutation<ResponseType, { token: string }>({
			query: ({ token }) => ({
				url: `user_app/staff/lists/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		unitSearch: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: `admin_app/unit/search/`,
				method: 'POST',
				data: data,
				token,
			}),
		}),
		unitCreate: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: `admin_app/unit_save_data/`,
				method: 'POST',
				data: data,
				token,
			}),
		}),
		unitEdit: builder.mutation<ResponseType, { id: string, token: string }>({
			query: ({ id, token }) => ({
				url: `admin_app/unit_get_by_id/${id}/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		unitUpdate: builder.mutation<ResponseType, { id: string, data: any, token: string }>({
			query: ({ id, data, token }) => ({
				url: `admin_app/unit_update_data/${id}/`,
				method: 'PUT',
				data: data,
				token,
			}),
		}),
		unitDelete: builder.mutation<ResponseType, { id: string, token: string }>({
			query: ({ id, token }) => ({
				url: `admin_app/unit_del/${id}/`,
				method: 'DELETE',
				data: '',
				token,
			}),
		}),
		unitList: builder.mutation<ResponseType, { id: string, token: string }>({
			query: ({ id, token }) => ({
				url: `admin_app/unit/list/${id}/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		studentList: builder.mutation<ResponseType, { id: string, token: string }>({
			query: ({ id, token }) => ({
				url: `admin_app/student/lists/${id}/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		examCreate: builder.mutation<ResponseType, { inputs: { exam_title: string; name: string; course_id: string; role: string; total_marks: string; description: string; mode: string; staffs: string | never[]; students: never[]; unit: never[]; duration: string; }, token: string }>({
			query: ({ inputs, token }) => {
				const updatedInputs = { ...inputs, staffs: Array.isArray(inputs.staffs) ? inputs.staffs : [] };
				return {
					url: `admin_app/exam_save_data/`,
					method: 'POST',
					data: updatedInputs,
					token,
				};
			},
		}),
		updateExam: builder.mutation<ResponseType, { inputs: { exam_title: string; name: string; course_id: string; role: string; total_marks: string; description: string; mode: string; staffs: string | never[]; students: never[]; unit: never[]; duration: string; }, token: string, id: string }>({
			query: ({ inputs, token, id }) => {
				const updatedInputs = { ...inputs, staffs: Array.isArray(inputs.staffs) ? inputs.staffs : [] };
				return {
					url: `admin_app/exam/update/${id}`,
					method: 'PUT',
					data: updatedInputs,
					token,
				};
			},
		}),
		getExamPerformance: builder.mutation<ResponseType, { data: any, token: any }>({
			query: ({ data, token }) => ({
				url:`report_app/exam_performance_overview_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		getStudentExams: builder.mutation<ResponseType, { uuid: string, searchValue: string, token: string, page: number }>({
			query: ({ uuid, searchValue, token, page }) => ({
				url: `admin_app/widget/students/course/exams/lists/?course_uuid=${uuid}&search=${searchValue}&page=${page}&per_page=${10}`,
				method: 'GET',
				data: '', 
				token,
			}),
		}),
		getStudentCourseList: builder.mutation<ResponseType, { searchValue: string, token: string }>({
			query: ({ searchValue, token }) => ({
				url: `admin_app/widget/students/course/lists/?search=${searchValue}`,
				method: 'GET',
				data: '', 
				token,
			}),
		}),
		ExamDifficultyLevel: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url:`report_app/exam_difficulty_level_report/?uuid=${data?.id}&limit=${data?.limit}&page=${data?.page}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamEvaluationReport: builder.mutation<ResponseType, { data: any, token: string}>({
			query: ({ data, token }) => ({
				url: `report_app/exam_evaluation_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamCompletionRate: builder.mutation<ResponseType, { data: any, token: string}>({
			query: ({ data, token }) => ({
				url:`report_app/exam_completion_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamQuestionWisemarkReport: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: `report_app/exam_questionwise_mark_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamQuestionResponsetimeReport: builder.mutation<ResponseType, {data:any,token: string}>({
			query: ({ data, token }) => ({
				url: `report_app/exam_question_responsetime_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamQuestionTime: builder.mutation<ResponseType, { data: any, token: string}>({
			query: ({ data, token }) => ({
				url: `report_app/exam_questionwise_time_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamQuestionTypeAnalysis: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: `report_app/exam_questiontype_analysis_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamSectionMarkReport: builder.mutation<ResponseType, { data: any, token: string}>({
			query: ({ data, token }) => ({
				url: `report_app/exam_sectionwise_mark_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamTimeManagementReport: builder.mutation<ResponseType, { data: any, token: string}>({
			query: ({ data, token }) => ({
				url: `report_app/exam_time_management_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamUnitWiseMarkReport: builder.mutation<ResponseType, { data: any, token: string}>({
			query: ({ data, token }) => ({
				url: `report_app/exam_unitwise_mark_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamAttendance: builder.mutation<ResponseType, { data: any, token: string}>({
			query: ({ data, token }) => ({
				url: `report_app/exam_attendance_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		ExamPerformanceTrendAnalysis: builder.mutation<ResponseType, { data:any,token: string }>({
			query: ({ data, token }) => ({
				url: `report_app/exam_performance_trend_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&year=${data?.isyear}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),

		ExamStudentFailed: builder.mutation<ResponseType, { data:any,token: string }>({
			query: ({ data, token }) => ({
				url:`report_app/failed_student_report/?page=${data?.page}&limit=${data?.limit}&uuid=${data?.id}&search=${data?.search}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		
		Exampdfcsv: builder.mutation<ResponseType, { data:any,token: string }>({
			query: ({ data, token }) => ({
				url:`report_app/exam_csv_pdf_report/?uuid=${data?.id}&format_type=${data?.format_type}&report_type=${data?.report_type}`,
				method: 'GET',
				data: '',
				token,
			}),
		}),

		GetExam: builder.mutation<ResponseType, { token: string }>({
			query: ({ token }) => ({
				url: `admin_app/exam/publish/list/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		GetExamYear: builder.mutation<ResponseType ,{token: string}> ({
			query: ({ token }) => ({
			   url:`admin_app/exam/publish/list/`,
			   method: 'GET',
			   data: '',
			   token,
		   }),
	   }),
		GetCourseListByID: builder.mutation<ResponseType, { uuid: any, token: string }>({
			query: ({ uuid, token }) => ({
				url: `admin_app/courses/${uuid}/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
		updateCourseByID: builder.mutation<ResponseType, { specialisationUuid: any, CourseData: any, token: string }>({
			query: ({ specialisationUuid, CourseData, token }) => ({
				url: `admin_app/courses/update/${specialisationUuid}/`,
				method: 'PUT',
				data: CourseData,
				token,
			}),
		}),
		createCourseApi: builder.mutation<ResponseType, { CourseData: any, token: string }>({
			query: ({ CourseData, token }) => ({
				url: `admin_app/courses/create/`,
				method: 'POST',
				data: CourseData,
				token,
			}),
		}),
	
		deleteCourseByID: builder.mutation<ResponseType, { specialisationUuid: any, token: string }>({
			query: ({ specialisationUuid, token }) => ({
				url: `admin_app/courses/delete/${specialisationUuid}/`,
				method: 'DELETE',
				data: '',
				token,
			}),
		}),
		
		getCourseList: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: `admin_app/courses/?page=1&limit=100`,
				method: 'GET',
				data: data,
				token,
			}),
		}),
		getSpecialiastionList: builder.mutation<ResponseType, { data: any, token: string }>({
			query: ({ data, token }) => ({
				url: `admin_app/concentrations/`,
				method: 'GET',
				data: data,
				token,
			}),
		}),
		GetStudentList: builder.mutation<ResponseType, { token: string }>({
			query: ({ token }) => ({
				url: `user_app/student/search/`,
				method: 'GET',
				data: '',
				token,
			}),
		}),
	}),
});

export const {
	useCheckSubDomainMutation,
	useLoginMutation,
	useWidgetDashboardCountsMutation,
	useWidgetQuestionPaperAllotedMutation,
	useWidgetExamsProgressMutation,
	useWidgetStudentEntrolmentMutation,
	useGetProfileMutation,
	useProfileUpdateMutation,
	useExamListMutation,
	useExamScheduleUpdateMutation,
	useExamPublishUnPublishMutation,
	useExamStudentFailedMutation,
	useExampdfcsvMutation,
	// useExamPublishMutation,
	// useExamUnPublishMutation,
	useGetExamByIdMutation,
	useDeleteExamByIdMutation,
	useCourseSearchMutation,
	useCourseCreateMutation,
	useCourseUpdateMutation,
	useCourseEditMutation,
	useCourseDeleteMutation,
	useCourseListMutation,
	useStaffListMutation,
	useUnitListMutation,
	useUnitSearchMutation,
	useUnitCreateMutation,
	useUnitEditMutation,
	useUnitUpdateMutation,
	useUnitDeleteMutation,
	useStudentListMutation,
	useExamCreateMutation,
	useUpdateExamMutation,
	useGetExamPerformanceMutation,
	useGetStudentExamsMutation,
	useGetStudentCourseListMutation,
	useExamDifficultyLevelMutation,
	useExamEvaluationReportMutation,
	useExamCompletionRateMutation,
	useExamQuestionWisemarkReportMutation,
	useExamQuestionResponsetimeReportMutation,
	useExamQuestionTimeMutation,
	useExamQuestionTypeAnalysisMutation,
	useExamSectionMarkReportMutation,
	useExamTimeManagementReportMutation,
	useExamUnitWiseMarkReportMutation,
	useExamAttendanceMutation,
	useExamPerformanceTrendAnalysisMutation,
	useGetExamMutation,
	useGetCourseListByIDMutation,
	useGetExamYearMutation,
	useUpdateCourseByIDMutation,
	useCreateCourseApiMutation,
	useDeleteCourseByIDMutation,
	useGetCourseListMutation,
	useGetSpecialiastionListMutation,
	useGetStudentListMutation,

} = apiService;

export default apiService;