import React, { useEffect, useRef, ChangeEvent, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MdDragIndicator } from "react-icons/md";

const DraggableQuestion: React.FC<{
  id: string;
  question: string;
  choices: string[] | null;
  index: number;
  questionData: any;
  sectionIndex: number;
  handleInput: (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number,
    sectionIndex: number,
    keyName: string,
    choiceIndex?: number
  ) => void;
}> = ({
  id,
  question,
  choices,
  index,
  handleInput,
  questionData,
  sectionIndex,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="w-full  relative group rounded-md flex gap-2   "
    >
      <div
        className="absolute top-0 -left-28 cursor-move bg-GreatifyNeutral-50 rounded-sm p-[2px] opacity-0 group-hover:opacity-100 transition-opacity"
        {...attributes}
        {...listeners}
      >
        <MdDragIndicator className="w-6 h-auto " />
      </div>
      <div className="w-full relative h-fit   ">
        <textarea
          className="common-input w-full h-fit"
          value={question}
          rows={1}
          onChange={(e) => handleInput(e, index, sectionIndex, "question")}
        />

        {choices && choices.length > 0 && (
          <div className="grid grid-cols-2 gap-x-4 gap-y-0-">
            {choices.map((choice, choiceIndex) => (
              <div key={choiceIndex} className="flex items-start mt-1">
                <span className="mr-2">
                  {String.fromCharCode(97 + choiceIndex)}.
                </span>
                <textarea
                  className="common-input w-full"
                  value={choice}
                  rows={1}
                  onChange={(e) =>
                    handleInput(e, index, sectionIndex, "choice", choiceIndex)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex gap-2  h-fit pt-[6px]">
        <textarea
          className="common-input smallTextInput text-center text-xs "
          value={questionData?.marks}
          rows={1}
          onChange={(e) => handleInput(e, index, sectionIndex, "marks")}
        />
        <textarea
          className="common-input smallTextInput  text-center text-xs"
          value={questionData?.blooms}
          rows={1}
          onChange={(e) => handleInput(e, index, sectionIndex, "blooms")}
        />
        <textarea
          className="common-input smallTextInput text-center text-xs"
          value={questionData?.co}
          rows={1}
          onChange={(e) => handleInput(e, index, sectionIndex, "co")}
        />
        <textarea
          className="common-input smallTextInput text-center text-xs"
          value={questionData?.po}
          rows={1}
          onChange={(e) => handleInput(e, index, sectionIndex, "po")}
        />
      </div>
    </div>
  );
};

const TestingTemplate: React.FC = () => {
  const [realData, setRealData] = useState<any>({
    co_po: 0,
    notes: "",
    blooms: 0,
    header: {
      year: "2011",
      month: "",
      semester: "Third semester",
      exam_name: "MBA DEGREE EXAMINATION, June 2024",
      max_marks: "Max. Marks: 30",
      exam_hours: "Time: 30 hours",
      exam_title: "MBA DEGREE EXAMINATION, June 2024",
    },
    subject: {
      code: "Django",
      name: "DJ001",
      sub_title:
        "(For the candidates admitted from the academic year 2011 onwards)",
    },
    sections: [
      {
        mark: 10,
        title: "Part 1",
        either: 0,
        questions: [
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: ["Mount Everest", "K2", "Kangchenjunga", "Lhotse"],
            question: "What is the Largest mountain in the Asia?",
            question_id: "20a20195-63d1-410d-a94c-537526b161e2",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: ["Hydrogen", "Helium", "Lithium", "Boron"],
            question: "Which element not having the atomic number 1?",
            question_id: "b8f1505f-ab19-434e-813d-322bd34a3077",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: ["Cheetah", "Lion", "Leopard", "Tiger"],
            question: "What is the tallest land animal?",
            question_id: "ead9a347-e2a1-4882-a852-7bfda0add231",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: [
              "Isaac Newton",
              "Galileo Galilei",
              "Albert Einstein",
              "Niels Bohr",
            ],
            question: "Who developed the theory of Electricity?",
            question_id: "e1305916-cef3-464d-b791-9130cffd1a3f",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: [
              "George Orwell",
              "Aldous Huxley",
              "Ray Bradbury",
              "Philip K. Dick",
            ],
            question: "Who is the author of Gravity Force?",
            question_id: "1e12e509-4d01-47c9-8fca-0a1282a19f2c",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: ["Soybeans", "Tofu", "Noodles", "Rice"],
            question:
              "What is the main ingredient in traditional Indian miso soup?",
            question_id: "483c41e0-7bae-43cb-a8d6-63d032a16785",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: ["Oxygen", "Nitrogen", "Carbon Dioxide", "Argon"],
            question: "Which gas is most economic in the Transport?",
            question_id: "fb73f05e-4d48-40b8-960a-34a907cab427",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: ["Mount Everest", "K2", "Kangchenjunga", "Lhotse"],
            question: "What is the Latest Bus Terminal in the Asia?",
            question_id: "b52a3457-da75-4a47-a87f-dff5f4820210",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: ["Hydrogen", "Helium", "Lithium", "Boron"],
            question: "Which element is effecting the Solor Systems?",
            question_id: "7ec50181-4f73-4151-ad95-25e2706326f9",
          },
          {
            co: 1,
            po: 1,
            marks: 1,
            blooms: 1,
            choice: ["Cheetah", "Lion", "Leopard", "Tiger"],
            question: "What is the Longest River in India?",
            question_id: "a02939dd-34a9-46f3-a6e7-f3255a213426",
          },
        ],
        description: "Answer ALL the questions",
        question_type: "Multiple Choice Question",
        question_count: "10",
        mark_per_question: 1,
        required_question: 10,
        notes: "(Answer should not exceed 30 words)",
      },
      {
        mark: 20,
        title: "Part 2",
        either: 0,
        questions: [
          {
            co: 1,
            po: 1,
            marks: 4,
            blooms: 1,
            choice: null,
            question: "Name the three fields of systematics. ",
            question_id: "f31eab78-31ac-4c1d-9293-e212cdb90f0f",
          },
          {
            co: 1,
            po: 1,
            marks: 4,
            blooms: 1,
            choice: null,
            question: "Give the two name systems of organisms? ",
            question_id: "c7dd2e36-27af-4849-b526-7ff6de388acd",
          },
          {
            co: 1,
            po: 1,
            marks: 4,
            blooms: 1,
            choice: null,
            question:
              "Write the correct order of sequence of taxonomic categories? ",
            question_id: "866a21cd-fa2e-4353-91bf-c91b77be0d83",
          },
          {
            co: 1,
            po: 1,
            marks: 4,
            blooms: 1,
            choice: null,
            question: "Give the unit of classification? ",
            question_id: "55fa468b-8aa2-4058-aa65-56d70564f29e",
          },
          {
            co: 1,
            po: 1,
            marks: 4,
            blooms: 1,
            choice: null,
            question: "Who gave the binomial name of classification? ",
            question_id: "2d34eac3-6032-420a-8a4f-433c904d984b",
          },
        ],
        description: "Answer ALL the questions",
        question_type: "Descriptive Question",
        question_count: "5",
        mark_per_question: 4,
        required_question: 5,
      },
    ],
    part_count: "2",
    register_no: "8",
    questionPaperCode: "FHJK98734FH",
  });

  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto to allow shrinking
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height to match scrollHeight
    }
  };
  

  useEffect(() => {
  
    const textAreas = Array.from(document.querySelectorAll("textarea"));
    textAreas.forEach((textarea) => adjustHeight(textarea));
  }, [realData]);

  const handleDragEnd = (event: any, sectionIndex: number) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setRealData((prevData: any) => {
        // Get the current section's questions
        const sectionItems = prevData.sections[sectionIndex].questions;

        const oldIndex = sectionItems.findIndex(
          (item: any) => item.question === active.id
        );
        const newIndex = sectionItems.findIndex(
          (item: any) => item.question === over.id
        );

        // Move the item within the current section
        const updatedSectionItems = arrayMove(sectionItems, oldIndex, newIndex);

        // Update the real data state
        const updatedSections = [...prevData.sections];
        updatedSections[sectionIndex].questions = updatedSectionItems;

        return { ...prevData, sections: updatedSections };
      });
    }
  };

  const handleHeaderKeyInput = (
    event: ChangeEvent<HTMLTextAreaElement>,
    keyName: string
  ) => {
    const textarea = event.target;
    adjustHeight(textarea);
  
    setRealData((prevData: any) => {
      if (keyName) {
        return {
          ...prevData,
          header: {
            ...prevData.header,
            [keyName]: textarea.value,
          },
        };
      }
      return prevData;
    });
  };
  
  const handleSubjectKeyInput = (
    event: ChangeEvent<HTMLTextAreaElement>,
    keyName: string
  ) => {
    const textarea = event.target;
    
    // Adjust height before setting the value
    adjustHeight(textarea);
  
    setRealData((prevData: any) => {
      if (keyName) {
        return {
          ...prevData,
          subject: {
            ...prevData.subject,
            [keyName]: textarea.value,
          },
        };
      }
      return prevData;
    });
  };
  

  const handleInput = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number,
    sectionIndex: number,
    keyName: string,
    choiceIndex?: number
  ) => {
    const textarea = event.target;
    adjustHeight(textarea);

    setRealData((prevData: any) => {
      // Make a copy of the sections
      const updatedSections = [...prevData.sections];

      if (keyName === "description" || keyName === "notes") {
        // Update the section-level field (description or notes)
        updatedSections[sectionIndex][keyName] = textarea.value;
      } else {
        // Make a copy of the questions in the current section
        const updatedQuestions = [...updatedSections[sectionIndex].questions];

        if (choiceIndex !== undefined) {
          // Update the specific choice value
          updatedQuestions[index].choice![choiceIndex] = textarea.value;
        } else {
          // Update the specified key in the question object
          updatedQuestions[index][keyName] = textarea.value;
        }

        // Assign the updated questions back to the correct section
        updatedSections[sectionIndex].questions = updatedQuestions;
      }

      return { ...prevData, sections: updatedSections };
    });
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    contentRef: contentRef,
  });

  const sensors = useSensors(useSensor(MouseSensor));

  const IndexBoxes = ({ realData }: any) => {
    // Ensure `register_no` is a valid number before creating the array
    const boxCount = Number(realData?.register_no) + 1 || 0;

    return (
      <div className="flex border-GreatifyNeutral-300 border w-fit">
        {Array(boxCount)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className={`${index == 0 ? "w-auto px-2" : "w-10"} h-10 ${
                index == boxCount - 1 ? "" : "border-r"
              }  border-GreatifyNeutral-300 flex justify-center items-center`}
            >
              {index === 0 ? "Reg No" : ""}
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="bg-white shadow-lg w-[70%] mx-auto">
        <div ref={contentRef} className=" w-full report font-times">
          <table className="report-container">
            <thead className="report-header">
              <tr>
                <th className="report-header-cell">
                  <div className="header-info">{/* header content.... */}</div>
                </th>
              </tr>
            </thead>
            <tfoot className="report-footer">
              <tr>
                <td className="report-footer-cell">
                  <div className="footer-info">
                    <div className={"page-footer"}>
                      <p className="text-sm">{realData?.questionPaperCode}</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
            <tbody className="report-content">
              <tr>
                <td className="report-content-cell">
                  <div className="main">
                    <div ref={contentRef} className="px-8 py-4">
                      <div className="w-full flex items-end justify-end mb-4">
                        <IndexBoxes realData={realData} />
                      </div>
                      {/* Header and Subject Details */}
                      <div className="border-b border-GreatifyNeutral-300 pb-4 mb-4">
                        <div className="flex w-[70%] mx-auto flex-col items-center justify-center">
                          <textarea
                            className="common-input text-xl font-bold text-center"
                            value={realData.header.exam_title}
                            rows={1}
                            onChange={(e) =>
                              handleHeaderKeyInput(e, "exam_title")
                            }
                          />
                          <textarea
                            className="common-input text-center"
                            value={realData.header.semester}
                            rows={1}
                            onChange={(e) =>
                              handleHeaderKeyInput(e, "semester")
                            }
                          />
                          <div className="flex gap-2  w-full mt-2">
                            <textarea
                              className="common-input font-semibold  text-right"
                              value={`${realData.subject.code}`}
                              rows={1}
                              onChange={(e) => handleSubjectKeyInput(e, "code")}
                            />
                            <span className="font-semibold">-</span>
                            <textarea
                              className="common-input font-semibold w-full"
                              value={`${realData.subject.name}`}
                              rows={1}
                              onChange={(e) => handleSubjectKeyInput(e, "name")}
                            />
                          </div>
                          <textarea
                            className="common-input text-center italic text-sm"
                            value={`${realData.subject.sub_title}`}
                            rows={1}
                            onChange={(e) =>
                              handleSubjectKeyInput(e, "sub_title")
                            }
                          />
                        </div>
                        <div className="flex gap-8 justify-between mt-4">
                          <div className="w-full flex gap-2">
                            <textarea
                              className="common-input text-left"
                              value={`${realData.header.max_marks}`}
                              rows={1}
                              onChange={(e) =>
                                handleHeaderKeyInput(e, "max_marks")
                              }
                            />
                          </div>
                          <div className="w-full flex gap-2 justify-end">
                            <textarea
                              className="common-input text-right "
                              value={`${realData.header.exam_hours}`}
                              rows={1}
                              onChange={(e) =>
                                handleHeaderKeyInput(e, "exam_hours")
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {realData.sections.map(
                        (section: any, sectionIndex: number) => (
                          <div className="mb-8" key={sectionIndex}>
                            <div className="flex flex-col items-center justify-center mb-3">
                              <textarea
                                className="common-input text-lg font-semibold text-center"
                                value={section.description}
                                rows={1}
                                onChange={(e) =>
                                  handleInput(e, 0, sectionIndex, "description")
                                }
                              />
                              <p className="font-semibold">
                                {section.title} ({section.question_count} x{" "}
                                {section.mark_per_question} = {section.mark})
                              </p>
                              <textarea
                                className="common-input font-semibold text-center  text-sm"
                                value={section.notes}
                                rows={1}
                                onChange={(e) =>
                                  handleInput(e, 0, sectionIndex, "notes")
                                }
                              />
                            </div>

                            {/* Questions Section */}
                            <div className="flex gap-2 mb-4">
                              <div className="w-full "></div>
                              <div className="flex gap-2">
                                <p className="font-semibold text-xs w-[35px] text-center">
                                  Marks
                                </p>
                                <p className="font-semibold text-xs w-[35px] text-center  ">
                                  BL
                                </p>
                                <p className="font-semibold text-xs w-[35px] text-center ">
                                  CO
                                </p>
                                <p className="font-semibold text-xs w-[35px] text-center ">
                                  PO
                                </p>
                              </div>
                            </div>
                            <DndContext
                              sensors={sensors}
                              collisionDetection={closestCenter}
                              onDragEnd={(event) =>
                                handleDragEnd(event, sectionIndex)
                              }
                            >
                              <SortableContext
                                items={section?.questions?.map(
                                  (item: any) => item.question
                                )}
                                strategy={verticalListSortingStrategy}
                              >
                                <div className="flex flex-col gap-2 ">
                                  {section?.questions.map(
                                    (item: any, index: number) => (
                                      <div
                                        className="flex"
                                        key={item.question_id}
                                      >
                                        <span className="mr-2">
                                          {index + 1}.
                                        </span>
                                        <DraggableQuestion
                                          id={item.question}
                                          index={index}
                                          question={item.question}
                                          choices={item.choice}
                                          handleInput={handleInput}
                                          sectionIndex={sectionIndex}
                                          questionData={item}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </SortableContext>
                            </DndContext>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-4">
        <button
          onClick={(e) => handlePrint()}
          className="w-fit h-fit px-12 py-3 greenGradient rounded-md  text-white font-medium  "
        >
          Print
        </button>
      </div>
    </div>
  );
};

export default TestingTemplate;
