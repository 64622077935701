import React, { FC, Suspense, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { UserDataComponent } from "../components/UserData";
import { getApp } from "../config/appConfig"; // Update this import to come from appConfig instead of helpers
import { useDispatch } from "react-redux";
import { logout } from "../reducers/userReducer";
import { useCheckSubDomainMutation } from "../redux/api";
import { toast } from "react-toastify";
import StudentContext from "../views/Dashboards/StudentContext";
import { TestingEditors } from "../components/common/Editors/TestingEditors";
// import CreateSingleQuestion from "..//views/teacher/Question/Bank/CreateSingleQuestion";
// import ProtectedRoutes from "../utils/ProtectedRoutes";

// Student Routes
const TestScreen = React.lazy(() =>
  import("../views/students/TestScreen").then((module) => ({
    default: module.TestScreen,
  }))
);

const Courses = React.lazy(() =>
  import("../views/students/Courses").then((module) => ({
    default: module.Courses,
  }))
);

const CourseExam = React.lazy(() =>
  import("../views/students/CourseExams").then((module) => ({
    default: module.CourseExams,
  }))
);

const Instructions = React.lazy(() =>
  import("../views/students/Instructions").then((module) => ({
    default: module.Instructions,
  }))
);

// Layout Routes
const DefaultLayout = React.lazy(() =>
  import("../components/layouts/Layout").then((module) => ({
    default: module.DefaultLayout,
  }))
);

const GuestLayout = React.lazy(() =>
  import("../components/layouts/auth/Layout").then((module) => ({
    default: module.GuestLayout,
  }))
);

// Auth Routes
const Dashboard = React.lazy(() =>
  import("../views/Dashboard").then((module) => ({
    default: module.Dashboard,
  }))
);

const Login = React.lazy(() =>
  import("../views/auth/login").then((module) => ({
    default: module.Login,
  }))
);

const SignUp = React.lazy(() =>
  import("../views/auth/signup").then((module) => ({
    default: module.SignUpForm,
  }))
);

const ForgotPassword = React.lazy(() =>
  import("../views/auth/forgot-password").then((module) => ({
    default: module.ForgotPassword,
  }))
);

const EmailVerification = React.lazy(() =>
  import("../views/auth/email-verification").then((module) => ({
    default: module.EmailVerification,
  }))
);

const ChangePassword = React.lazy(() =>
  import("../views/auth/change-password").then((module) => ({
    default: module.ChangePassword,
  }))
);

// Admin Routes
const Invite = React.lazy(() =>
  import("../views/superadmin/Invite").then((module) => ({
    default: module.Invite,
  }))
);

const Onboarding = React.lazy(() =>
  import("../views/superadmin/Onboarding").then((module) => ({
    default: module.Onboarding,
  }))
);

const OrganizationView = React.lazy(() =>
  import("../components/common/OrganizationView").then((module) => ({
    default: module.OrganizationView,
  }))
);

// Error Routes
const NotFound = React.lazy(() =>
  import("../views/NotFound").then((module) => ({
    default: module.NotFound,
  }))
);

// Organization Routes
const Course = React.lazy(() =>
  import("../views/organization/course/Course").then((module) => ({
    default: module.Course,
  }))
);

const Unit = React.lazy(() =>
  import("../views/organization/unit/Unit").then((module) => ({
    default: module.Unit,
  }))
);

const Hierarchy = React.lazy(() =>
  import("../views/organization/Hierarchy/Hierarchy").then((module) => ({
    default: module.Hierarchy,
  }))
);

const Chapter = React.lazy(() =>
  import("../views/organization/chapter/Chapter").then((module) => ({
    default: module.Chapter,
  }))
);

// Question Routes
const Download = React.lazy(() =>
  import("../views/teacher/Question/Exam/Download").then((module) => ({
    default: module.Download,
  }))
);

const Exam = React.lazy(() =>
  import("../views/teacher/Question/Exam/Exam").then((module) => ({
    default: module.Exam,
  }))
);

const Question = React.lazy(() =>
  import("../views/teacher/Question/Question").then((module) => ({
    default: module.Question,
  }))
);

const QuestionBank = React.lazy(() =>
  import("../views/teacher/Question/Bank").then((module) => ({
    default: module.Bank,
  }))
);

const QuestionTypes = React.lazy(() =>
  import("../views/teacher/Question/Type").then((module) => ({
    default: module.Type,
  }))
);

const QuestionPaper = React.lazy(() =>
  import("../views/teacher/Question/Paper/Paper").then((module) => ({
    default: module.Paper,
  }))
);

const ExamView = React.lazy(() =>
  import("../views/teacher/Question/Exam/View").then((module) => ({
    default: module.View,
  }))
);

const View = React.lazy(() =>
  import("../views/teacher/Question/Paper/View").then((module) => ({
    default: module.View,
  }))
);

const Create = React.lazy(() =>
  import("../views/teacher/Question/Paper/Create").then((module) => ({
    default: module.Create,
  }))
);

const PaperEdit = React.lazy(() =>
  import("../views/teacher/Question/Paper/Edit").then((module) => ({
    default: module.Edit,
  }))
);

const PaperView = React.lazy(() =>
  import("../views/teacher/Question/Exam/PaperView").then((module) => ({
    default: module.PaperView,
  }))
);

const Evaluation = React.lazy(() =>
  import("../views/teacher/Question/Exam/evaluation").then((module) => ({
    default: module.Evaluation,
  }))
);

const AnswerKey = React.lazy(() =>
  import("../views/teacher/Question/AnswerKey").then((module) => ({
    default: module.AnswerKey,
  }))
);

// OBE Routes
const OBEConfiguration = React.lazy(() =>
  import("../views/obe/Configuration").then((module) => ({
    default: module.Configuration,
  }))
);

const BloomsConfiguration = React.lazy(() =>
  import("../views/obe/Blooms").then((module) => ({
    default: module.Blooms,
  }))
);

const OBE = React.lazy(() =>
  import("../views/obe/Obe").then((module) => ({
    default: module.Obe,
  }))
);

const PEO = React.lazy(() =>
  import("../views/obe/Peo").then((module) => ({
    default: module.Peo,
  }))
);

const CourseOutcome = React.lazy(() =>
  import("../views/obe/Co").then((module) => ({
    default: module.Co,
  }))
);

// Question Bank Routes
const ApproveQuestion = React.lazy(() =>
  import("../views/teacher/Question/Bank/ApproveQuestion").then((module) => ({
    default: module.ApproveQuestion,
  }))
);

const BankCreate = React.lazy(() =>
  import("./../views/teacher/Question/Bank/Create").then((module) => ({
    default: module.Create,
  }))
);

// Report Routes
const Reports = React.lazy(() =>
  import("../views/Reports/Configuration").then((module) => ({
    default: module.Configuration,
  }))
);

// Exam Report Routes
const OverAllExam = React.lazy(() =>
  import("../views/Reports/Exam/OverAllExam").then(
    (module) => ({
      default: module.OverAllExam,
    })
  )
);

const DifficultyLevelDistribution = React.lazy(() =>
  import("../views/Reports/Exam/DifficultyLevel").then(
    (module) => ({
      default: module.DifficultyLevelDistribution,
    })
  )
);

const QuestionResponseTimeAnalysis = React.lazy(() =>
  import("../views/Reports/Exam/QuestionResponseTime").then(
    (module) => ({
      default: module.QuestionResponseTimeAnalysis,
    })
  )
);

const ExamPerformanceOverview = React.lazy(() =>
  import("../views/Reports/Exam/ExamPerformanceOverview").then(
    (module) => ({
      default: module.ExamPerformanceOverview,
    })
  )
);

const ExamCompletionRate = React.lazy(() =>
  import("../views/Reports/Exam/ExamCompletionRate").then(
    (module) => ({
      default: module.ExamCompletionRate,
    })
  )
);

const AttendanceReport = React.lazy(() =>
  import("../views/Reports/Exam/AttendanceReport").then(
    (module) => ({
      default: module.AttendanceReport,
    })
  )
);

const QuestionTimeAnalysis = React.lazy(() =>
  import("../views/Reports/Exam/QuestionTimeAnalysis").then(
    (module) => ({
      default: module.QuestionTimeAnalysis,
    })
  )
);

const StudentFailed = React.lazy(() =>
  import("../views/Reports/Exam/StudentFailed").then(
    (module) => ({
      default: module.StudentFailed,
    })
  )
);

const QuestionMarkAnalysis = React.lazy(() =>
  import("../views/Reports/Exam/QuestionMark").then(
    (module) => ({
      default: module.QuestionMarkAnalysis,
    })
  )
);

const EvaluationReport = React.lazy(() =>
  import("../views/Reports/Exam/EvaluationReport").then(
    (module) => ({
      default: module.EvaluationReport,
    })
  )
);

const SectionMarkReportAnalysis = React.lazy(() =>
  import("../views/Reports/Exam/SectionMark").then(
    (module) => ({
      default: module.SectionMarkReportAnalysis,
    })
  )
);

const UnitWiseMarkReport = React.lazy(() =>
  import("../views/Reports/Exam/UnitWiseMarkReport").then(
    (module) => ({
      default: module.UnitWiseMarkReport,
    })
  )
);

const QuestionTypeAnalysis = React.lazy(() =>
  import("../views/Reports/Exam/QuestionTypeAnalysis").then(
    (module) => ({
      default: module.QuestionTypeAnalysis,
    })
  )
);

const TimeManagementReport = React.lazy(() =>
  import("../views/Reports/Exam/TimeManagementReport").then(
    (module) => ({
      default: module.TimeManagementReport,
    })
  )
);

const SubjectWisePerformanceComparison = React.lazy(() =>
  import(
    "../views/Reports/Exam/SubjectWisePerformanceComparison"
  ).then((module) => ({
    default: module.SubjectWisePerformanceComparison,
  }))
);

const PerformanceTrendAnalysis = React.lazy(() =>
  import(
    "../views/Reports/Exam/PerformanceTrendAnalysis"
  ).then((module) => ({
    default: module.PerformanceTrendAnalysis,
  }))
);

// Course Report Routes
const PerformanceTrendAnalysisIndividual = React.lazy(() =>
  import(
    "../views/Reports/Course/PerformanceTrendIndividual"
  ).then((module) => ({
    default: module.PerformanceTrendAnalysisIndividual,
  }))
);

const ExamCompletionRateIndividual = React.lazy(() =>
  import(
    "../views/Reports/Course/ExamComletionIndividual"
  ).then((module) => ({
    default: module.ExamCompletionRateIndividual,
  }))
);

const QuestionResponseTimeAnalysisIndividual = React.lazy(() =>
  import(
    "../views/Reports/Course/QuestionResponceTimeIndividual"
  ).then((module) => ({
    default: module.QuestionResponseTimeAnalysisIndividual,
  }))
);

const SubjectwisePerformanceComparisonIndividual = React.lazy(() =>
  import(
    "../views/Reports/Course/SubjectPerformanceIndividual"
  ).then((module) => ({
    default: module.SubjectwisePerformanceComparisonIndividual,
  }))
);

const DifficultyLevelDistributionIndividual = React.lazy(() =>
  import(
    "../views/Reports/Course/DifficultyLevelIndividual"
  ).then((module) => ({
    default: module.DifficultyLevelDistributionIndividual,
  }))
);

const TimeManagementReportIndividual = React.lazy(() =>
  import(
    "../views/Reports/Course/TimeManagementIndividual"
  ).then((module) => ({
    default: module.TimeManagementReportIndividual,
  }))
);

const ExamPerformanceIndividual = React.lazy(() =>
  import(
    "../views/Reports/Course/ExamPerformanceIndividual"
  ).then((module) => ({
    default: module.ExamPerformanceIndividual,
  }))
);

const OverAllCourse = React.lazy(() =>
  import("../views/Reports/Course/overAllCourse").then(
    (module) => ({
      default: module.OverAllCourse,
    })
  )
);

// Student Report Routes
const OverAllStudentView = React.lazy(() =>
  import("../views/Reports/Student/OverAllStudentView").then(
    (module) => ({
      default: module.OverAllStudentView,
    })
  )
);

const StudentprogressAcrossBloomsTaxonomy = React.lazy(() =>
  import(
    "../views/Reports/Student/progressAcrossBloomsTaxonomy"
  ).then((module) => ({
    default: module.progressAcrossBloomsTaxonomy,
  }))
);

const StudenttimeManagementReport = React.lazy(() =>
  import("../views/Reports/Student/TimeManagementReport").then(
    (module) => ({
      default: module.timeManagementReport,
    })
  )
);

const StudentlearningObjective = React.lazy(() =>
  import("../views/Reports/Student/LearningObjective").then(
    (module) => ({
      default: module.learningObjective,
    })
  )
);

const StudentLearningObjectiveMastry = React.lazy(() =>
  import(
    "../views/Reports/Student/LearningObjectiveMastry"
  ).then((module) => ({
    default: module.LearningObjectiveMastry,
  }))
);

const StudenttimeSpent = React.lazy(() =>
  import("../views/Reports/Student/TimeSpent").then(
    (module) => ({
      default: module.timeSpent,
    })
  )
);

const StudentwebDepth = React.lazy(() =>
  import("../views/Reports/Student/WebDepth").then(
    (module) => ({
      default: module.webDepth,
    })
  )
);

// Teacher Report Routes
const OverAllTeacherView = React.lazy(() =>
  import("../views/Reports/Teacher/TeacherDashboardView").then(
    (module) => ({
      default: module.OverAllTeacherView,
    })
  )
);

const TeacherDifficultyLevelDistribution = React.lazy(() =>
  import("../views/Reports/Teacher/DifficultyLevel").then(
    (module) => ({
      default: module.DifficultyLevelDistribution,
    })
  )
);

const TeacherQuestionDifficultyAnalysis = React.lazy(() =>
  import(
    "../views/Reports/Teacher/QuestionDifficultyAnalysis"
  ).then((module) => ({
    default: module.QuestionDifficultyAnalysis,
  }))
);

const TeacherTopicwisePerformanceBreakdown = React.lazy(() =>
  import(
    "../views/Reports/Teacher/TopicwisePerformanceBreakdown"
  ).then((module) => ({
    default: module.TopicwisePerformanceBreakdown,
  }))
);

const TeacherTimeManagementAnalysis = React.lazy(() =>
  import(
    "../views/Reports/Teacher/TimeManagementAnalysis"
  ).then((module) => ({
    default: module.TimeManagementAnalysis,
  }))
);



// Exam Routes
const OfflineExam = React.lazy(() =>
  import("../views/teacher/Question/Exam/OfflineExam").then((module) => ({
    default: module.OfflineExam,
  }))
);

const ComparisonReport = React.lazy(() =>
  import("../views/teacher/Question/Exam/ComparisonReport").then((module) => ({
    default: module.ComparisonReport,
  }))
);

const QuestionSummary = React.lazy(() =>
  import("../views/teacher/Question/Paper/PaperView").then((module) => ({
    default: module.QuestionSummary,
  }))
);

//Management Routes

const University = React.lazy(() =>
  import(
    "../views/organization/Hierarchy/InstitutionalStructure/University"
  ).then((module) => ({
    default: module.University,
  }))
);
const Campus = React.lazy(() =>
  import("../views/organization/Hierarchy/InstitutionalStructure/Campus").then(
    (module) => ({
      default: module.Campus,
    })
  )
);

const College = React.lazy(() =>
  import("../views/organization/Hierarchy/InstitutionalStructure/College").then(
    (module) => ({
      default: module.College,
    })
  )
);

const Degree = React.lazy(() =>
  import("../views/obe/Degree").then((module) => ({
    default: module.Degree,
  }))
);

const Regulation = React.lazy(() =>
  import("../views/obe/Regulation").then((module) => ({
    default: module.Regulation,
  }))
);

const Department = React.lazy(() =>
  import("../views/obe/Department").then((module) => ({
    default: module.Department,
  }))
);

const Program = React.lazy(() =>
  import("../views/obe/Program").then((module) => ({
    default: module.Program,
  }))
);

const Specialisation = React.lazy(() =>
  import("../views/obe/Specialisation").then((module) => ({
    default: module.Specialisation,
  }))
);

const Staff = React.lazy(() =>
  import("../views/organization/staff/Staff").then((module) => ({
    default: module.Staff,
  }))
);

const Student = React.lazy(() =>
  import("../views/organization/student/Student").then((module) => ({
    default: module.Student,
  }))
);

const Roles = React.lazy(() =>
  import("../views/Management/Role/view").then((module) => ({
    default: module.Roles,
  }))
);

const Template = React.lazy(() =>
  import("../views/organization/Template/Template").then((module) => ({
    default: module.Template,
  }))
);

const CourseHierarchy = React.lazy(() =>
  import(
    "../views/organization/Hierarchy/InstitutionalStructure/CourseHierarchy"
  ).then((module) => ({
    default: module.CourseHierarchy,
  }))
);

const ReportDownload = React.lazy(() =>
  import("../views/teacher/Question/Exam/Report").then((module) => ({
    default: module.Report,
  }))
);

export const AppRouter: FC = () => {
  const { role_name } = UserDataComponent();
  const [notExist, NotExist] = useState<boolean>(false);
  const [isOnboard, setIsOnboard] = useState<boolean>(true);
  return (
    <Suspense>
      <Routes>
        {role_name ? (
          <>
            <Route path="/" element={<DefaultLayout />}>
              <Route index element={<Navigate to="/dashboard" replace />} />
              <Route path="login" element={<Login isOnboard={isOnboard} />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="invite" element={<Invite />} />
              <Route
                path="organization/:changeType/:clickType/:uuid"
                element={<OrganizationView />}
              />
              <Route path="*" element={<NotFound notExist={notExist} />} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/" element={<GuestLayout />}>
              <Route index element={<Login isOnboard={isOnboard} />} />
              <Route path="login" element={<Login isOnboard={isOnboard} />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route
                path="email-verification"
                element={<EmailVerification />}
              />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="*" element={<NotFound notExist={notExist} />} />
            </Route>
          </>
        )}
      </Routes>
    </Suspense>
  );
};

export const MainRouter: FC = () => {
  const { role_name, userData } = UserDataComponent();
  const dispatch = useDispatch();
  const CurrentApp = getApp();
  const domain = CurrentApp["subdomain"];
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [subdomainLoading, setSubdomainLoading] = useState<boolean>(false);
  const [notExist, NotExist] = useState<boolean>(false);
  const [isDomain, setIsDomain] = useState<boolean>(false);
  const [isOnboard, setIsOnboard] = useState<boolean>(false);

  const navigate = useNavigate();
  const [checkSubDomain] = useCheckSubDomainMutation();

  useEffect(() => {
    if (!isDataFetched) {
      const checkDomain = async () => {
        try {
          setSubdomainLoading(true);
          const response: any =
            (await checkSubDomain().unwrap());
          if (response && response.status.code === 200) {
            setIsDomain(true);
            setIsDataFetched(true);
            setSubdomainLoading(false);
            if (response.data.onboard_status === 0) {
              setIsOnboard(false);
            } else {
              setIsOnboard(true);
              if (
                userData &&
                userData.user &&
                userData.user.username === response.data.username
              ) {
              } else {
                if (response.data.site_name == domain) {
                } else {
                  dispatch(logout());
                  navigate(`/login`);
                }
              }
            }
          }
        } catch (err) {
          dispatch(logout());
          NotExist(true);
          setIsDomain(false);
          setSubdomainLoading(false);

          if (err instanceof Error) {
            toast.error(`Error: ${err.message}`);
          } else if (typeof err === "object" && err !== null) {
            if (
              "data" in err &&
              typeof err.data === "object" &&
              err.data !== null
            ) {
              if ("message" in err.data) {
                toast.error(`Error: ${err.data.message}`);
              } else if ("data" in err.data) {
                toast.error(`Error: ${err.data.data}`);
              } else {
                toast.error(`Error: ${JSON.stringify(err.data)}`);
              }
            } else if ("message" in err) {
              toast.error(`Error: ${err.message}`);
            } else if ("data" in err) {
              toast.error(`Error: ${err.data}`);
            } else {
              toast.error(`Error: ${JSON.stringify(err)}`);
            }
          } else {
            toast.error("An unknown error occurred");
          }
        }
      };
      checkDomain();
    }
  }, [checkSubDomain, isDataFetched]);

  return (
    <Suspense>
      <Routes>
        {role_name && isDomain ? (
          <>
            <Route
              element={<TestScreen />}
              path={`/courses/:uuid/exam/:examId/test`}
            />
            <Route path="/" element={<DefaultLayout />}>
              <Route element={<Login isOnboard={isOnboard} />} path="/login" />
              <Route index element={<Navigate to="/dashboard" replace />} />
              <Route element={<Dashboard />} path="/dashboard" />
              <Route
                element={
                  <StudentContext>
                    <Courses />
                  </StudentContext>
                }
                path="/courses"
              />
              <Route
                element={
                  <StudentContext>
                    <CourseExam />
                  </StudentContext>
                }
                path="/courses/:uuid"
              />
              <Route
                element={
                  <StudentContext>
                    <Evaluation pageEvaluate={1} />
                  </StudentContext>
                }
                path="/courses/:uuid/online-evaluation/:exam_uuid"
              />
              <Route
                element={
                  <StudentContext>
                    <OfflineExam />
                  </StudentContext>
                }
                path="/courses/:uuid/offline-evaluation/:exam_uuid"
              />
              <Route
                element={
                  <StudentContext>
                    <Instructions />
                  </StudentContext>
                }
                path="/courses/:uuid/exam/:examId/instructions"
              />
              <Route element={<Course />} path="/course" />
              <Route element={<Unit />} path="/course/:uuid/unit" />
              <Route
                element={<Chapter />}
                path="/course/:course_id/unit/:uuid/chapter"
              />
              <Route element={<Exam />} path="/exam" />
              <Route element={<ExamView />} path="/exam/view/:uuid" />
              <Route element={<Question />} path="/authoring">
                <Route element={<QuestionPaper />} path="" />
                <Route element={<QuestionBank />} path="question-bank" />
                
                <Route element={<QuestionTypes />} path="question-types" />
                <Route element={<Download />} path="result_downloads" />
              </Route>
              {/* <Route
                  element={<CreateSingleQuestion tagList={[]} setIsEdit={() => { } } open={false} onClose={function (): void {
                  throw new Error("Function not implemented.");
                } } typeName={undefined} onSuccessfullCreation={function (): void {
                  throw new Error("Function not implemented.");
                } } editLoading={false} />}
                  path="/authoring/question-bank/create"
                /> */}
              <Route element={<Create />} path="/authoring/create" />
              <Route element={<View />} path="/authoring/view/:uuid" />
              <Route element={<PaperEdit />} path="/authoring/edit/:uuid" />
              <Route
                element={<QuestionSummary />}
                path="/authoring/question-summary/:uuid"
              />
              <Route
                element={<AnswerKey />}
                path="/authoring/paper/answer-key/:uuid"
              />
              {["exam", "authoring"].map((type: any) => (
                <React.Fragment key={type}>
                  <Route
                    element={<PaperView />}
                    path={`/${type}/paper/view/:uuid`}
                  />
                </React.Fragment>
              ))}
              <Route
                element={<Evaluation pageEvaluate={0} />}
                path="/exam/evaluation/:uuid"
              />
              <Route element={<Download />} path="/exam/result-download" />
              <Route element={<Regulation />} path="/regulation" />
              <Route element={<Department />} path="/department" />
              <Route element={<Hierarchy />} path="/management/hierarchy" />

              <Route element={<OBEConfiguration />} path="/configuration">
                <Route element={<BloomsConfiguration />} path="" />
                <Route element={<OBE />} path="obe" />
                <Route element={<PEO />} path="peo" />
                <Route element={<CourseOutcome />} path="course-outcome" />
              </Route>
              <Route
                element={<ApproveQuestion />}
                path="/authoring/question-bank/approve-questions"
              />
              <Route
                element={<BankCreate />}
                path="/question-bank/create-question-bank"
              />
              {/* {[
                "university",
                "campus",
                "college",
                "regulation",
                "department",
                "program",
                "concentration",
                "degree",
                "templates",
                "roles",
                "staff",
                "student",
                "course",
                "setting",
                "unit",
              ].map((type) => (
                <React.Fragment key={type}>
                  <Route element={<Hierarchy />} path={`/management/${type}`} />
                  <Route
                    path="/management"
                    element={<Navigate to="/management/hierarchy" replace />}
                  />
                </React.Fragment>
              ))} */}


              <Route element={<ReportDownload />} path="/exam/report/:uuid"/>

              <Route element={<Hierarchy />} path="/management">
                <Route element={<University />} index />
                <Route element={<Campus />} path="campus" />
                <Route element={<College />} path="college" />

                <Route element={<Degree hideBreadCrumbs />} path="degree" />
                <Route
                  element={<Department hideBreadCrumbs />}
                  path="department"
                />
                <Route element={<Program hideBreadCrumbs />} path="program" />
                <Route
                  element={<Specialisation hideBreadCrumbs />}
                  path="specialization"
                />
                <Route element={<Course hideBreadCrumbs />} path="course" />

                <Route element={<Unit />} path="course/:uuid/unit" />
                <Route
                  element={<Chapter />}
                  path="course/:course_id/unit/:uuid/chapter"
                />

                <Route
                  element={<Regulation hideBreadCrumbs />}
                  path="regulation"
                />

                <Route element={<Staff />} path="staff" />
                <Route element={<Student />} path="student" />
                <Route element={<Roles />} path="roles" />
                <Route element={<Template />} path="template" />
                <Route element={<CourseHierarchy />} path="settings" />
              </Route>

              <Route
                element={<OfflineExam />}
                path="/exam/offline-evaluate/:uuid"
              />
              <Route
                element={<ComparisonReport />}
                path="/exam/view-report/:uuid"
              />
              <Route element={<Reports />} path="/reports/" />
              <Route element={<OverAllExam />} path="/reports/exam_over_all" />
              <Route
                element={<PerformanceTrendAnalysis />}
                path="/reports/exam/performance-trend"
              />
              <Route
                element={
                  <DifficultyLevelDistribution examId={""} allView={false} />
                }
                path="/reports/exam/difficulty"
              />
              <Route
                element={
                  <QuestionResponseTimeAnalysis examId={""} allView={false} />
                }
                path="/reports/exam/time-report"
              />
              <Route
                element={<SubjectWisePerformanceComparison />}
                path="/reports/subjectwise_report"
              />
              <Route
                element={
                  <ExamPerformanceOverview examId={""} allView={false} />
                }
                path="/reports/exam/performance"
              />
              <Route
                element={<ExamCompletionRate examId={""} allView={false} />}
                path="/reports/exam/completion"
              />
              <Route element={<StudentFailed examId={""} allView={false}/>}
              path="/reports/exam/studentfailed"/>
              <Route
                element={<PerformanceTrendAnalysisIndividual />}
                path="/reports/performance_trend_analysis_individual"
              />
              <Route
                element={<QuestionResponseTimeAnalysisIndividual />}
                path="/reports/questionresponse_timeanalysis_individual"
              />
              <Route
                element={<SubjectwisePerformanceComparisonIndividual />}
                path="/reports/subjectwise_performance_comparisonindividual"
              />
              <Route
                element={<ExamCompletionRateIndividual />}
                path="/reports/exam_completionrate_individual"
              />
              <Route
                element={<DifficultyLevelDistributionIndividual />}
                path="/reports/difficulty_level_distribution_individual"
              />
              <Route
                element={<TimeManagementReportIndividual />}
                path="/reports/time_management_report_individual"
              />
              <Route
                element={<ExamPerformanceIndividual />}
                path="/reports/exam_performance_individual"
              />
              <Route
                element={<OverAllCourse />}
                path="/reports/over_all_course"
              />
              <Route
                element={<OverAllStudentView />}
                path="/reports/overallstudentview"
              />
              <Route
                element={<StudentprogressAcrossBloomsTaxonomy />}
                path="/reports/student_progress_across_blooms_taxonomy"
              />
              <Route
                element={<StudenttimeManagementReport />}
                path="/reports/student_time_management_report"
              />
              <Route
                element={<StudentlearningObjective />}
                path="/reports/student_learning_Objective"
              />
              <Route
                element={<StudentLearningObjectiveMastry />}
                path="/reports/student_learning_objective_mastry"
              />
              <Route
                element={<StudenttimeSpent />}
                path="/reports/student_time_spent"
              />
              <Route
                element={<StudentwebDepth />}
                path="/reports/student_web_depth"
              />
              <Route
                element={<AttendanceReport examId={""} allView={false} />}
                path="/reports/exam/attendance"
              />
              <Route
                element={<QuestionTimeAnalysis examId={""} allView={false} />}
                path="/reports/exam/time-analysis"
              />
              <Route
                element={<QuestionMarkAnalysis examId={""} allView={false} />}
                path="/reports/exam/question-analysis"
              />
              <Route
                element={<EvaluationReport examId={""} allView={false} />}
                path="/reports/exam/evaluation"
              />
              <Route
                element={
                  <SectionMarkReportAnalysis examId={""} allView={false} />
                }
                path="/reports/exam/section-report"
              />
              <Route
                element={<UnitWiseMarkReport examId={""} allView={false} />}
                path="/reports/exam/unit-report"
              />
              <Route
                element={<QuestionTypeAnalysis examId={""} allView={false} />}
                path="/reports/exam/question-type"
              />
              <Route
                element={<TimeManagementReport examId={""} allView={false} />}
                path="/reports/exam/time-mgmt"
              />
              <Route
                element={<OverAllTeacherView />}
                path="/reports/overall-teacher-view"
              />
              <Route
                element={<TeacherDifficultyLevelDistribution />}
                path="/reports/teacher-difficulty-level-distribution"
              />
              <Route
                element={<TeacherQuestionDifficultyAnalysis />}
                path="/reports/teacher-question-difficulty-analysis"
              />
              <Route
                element={<TeacherTimeManagementAnalysis />}
                path="/reports/teacher-time-management-analysis"
              />
              <Route
                element={<TeacherTopicwisePerformanceBreakdown />}
                path="/reports/teacher-topic-wise-performance-breakdown"
              />
            </Route>
            <Route path="*" element={<NotFound notExist={notExist} />} />
            <Route
              element={<TestScreen />}
              path="/courses/:uuid/exam/:examId/test"
            />
            <Route path="/testing-editors" element={<TestingEditors />} />
          </>
        ) : !isDomain ? (
          <Route
            path="/*"
            element={
              <NotFound notExist={notExist} loading={subdomainLoading} />
            }
          />
        ) : (
          <>
            <Route path="/" element={<GuestLayout />}>
              <Route index element={<Login isOnboard={isOnboard} />} />
              <Route path="login" element={<Login isOnboard={isOnboard} />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route
                path="email-verification"
                element={<EmailVerification />}
              />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="onboarding/:uuid" element={<Onboarding />} />
              <Route path="*" element={<NotFound notExist={notExist} />} />
            </Route>
          </>
        )}
      </Routes>
    </Suspense>
  );
};
