import { createContext, ReactNode, useState } from "react";
import React from "react";

const LanguageContext = createContext<{
    language:string,
    setLanguage: (lang:string) => void
}>({
    language:"en",
    setLanguage:()=>{}
})

interface LanguageProviderProps {
    children:ReactNode
}

export const LanguageContextProvider:React.FC<LanguageProviderProps> = ({children})=> {
    const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "en")
    return <LanguageContext.Provider value={{
        language, setLanguage
    }}>
        {children}
       </LanguageContext.Provider>

}

export default LanguageContext;
