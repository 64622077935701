import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { callApi, service } from "./../api/service";
import { toast } from "react-toastify";
import { UserDataComponent } from "./../components/UserData";
import { useDispatch } from "react-redux";
import { useGetProfileMutation } from "../redux/api";

// Define an interface for the context value
interface MyContextType {
  showAImodal: boolean;
  setShowAImodal: React.Dispatch<React.SetStateAction<boolean>>;
  notFoundModal: boolean;
  setNotFoundModal: React.Dispatch<React.SetStateAction<boolean>>;
  notFoundDetails: any;
  setNotFoundDetails: any;
  commonCourses: any;
  setCommonCourses?: () => void;
  courseDropdown?: any[];
  setCourseDropdown?: () => void;
  questionDropdown?: any[];
  setQuestionDropdown?: () => void;
  sideBarResize: boolean;
  setSideBarResize: React.Dispatch<React.SetStateAction<boolean>>;
  variantAImodal?: string;
  setVariantAImodal?: any;
  staffList?: any;
  setStaffList: any;
  poList?: any[];
  coList?: any[];
  blooms?: any[];
  questionDetailModal: boolean;
  setQuestionDetailModal?: any;
  questionDetail?: any;
  setQuestionDetail?: any;

}

const MyContext = createContext<MyContextType | undefined>(undefined);

const MyContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { token, role_name } = UserDataComponent();

  const [showAImodal, setShowAImodal] = useState(false);
  const [variantAImodal, setVariantAImodal] = useState("");
  const [notFoundModal, setNotFoundModal] = useState(false);
  const [notFoundDetails, setNotFoundDetails] = useState<any>({
    content: "",
    redirect: "",
  });
  const [commonCourses, setCommonCourses] = useState<any>([]);
  const [courseDropdown, setCourseDropdown] = useState<any>([]);
  const [questionDropdown, setQuestionDropdown] = useState<any>([]);

  const [sideBarResize, setSideBarResize] = useState<boolean>(false);
  const [staffList, setStaffList] = useState([]);
  const [poList, setPoList] = useState([]);
  const [coList, setCoList] = useState([]);
  const [blooms, setBlooms] = useState([]);

  const [questionDetailModal, setQuestionDetailModal] = useState(false);
  const [questionDetail, setQuestionDetail] = useState({});

  const dispatch = useDispatch()
  const [getProfile] = useGetProfileMutation();

  const handleProgramOutcomes = async () => {
    try {
      const responseData: any = await service.getAllProgramOutcomes(token);
      if (responseData.status.code === 200) {
        const lists = responseData.data.map((list: any) => ({
          label: list.code,
          value: list.unique_id,
        }));
        setPoList(lists);
      } else {
        toast.error("Failed to fetch blooms level", { autoClose: 1000 });
      }
    } catch (error) {
      toast.error("Failed to fetch blooms level", { autoClose: 1000 });
    }
  };

  const handleCourseOutcomes = async () => {
    try {
      const responseData: any = await service.getAllCourseOutcomes(token);
      if (responseData.status.code === 200) {
        const lists = responseData.data.map((list: any) => ({
          label: list.code,
          value: list.uuid,
        }));
        setCoList(lists);
      } else {
        toast.error("Failed to fetch blooms level", { autoClose: 1000 });
      }
    } catch (error) {
      toast.error("Failed to fetch blooms level", { autoClose: 1000 });
    }
  };

  const handleBlooms = async () => {
    try {
      const responseData: any = await service.getAllBloomsLevel(token);

      if (responseData.status.code === 200) {
        const lists = responseData.data.map((list: any) => ({
          label: list.name,
          value: list.uuid,
        }));
        setBlooms(lists);
      } else {
        toast.error("Failed to fetch blooms level", { autoClose: 1000 });
      }
    } catch (error) {
      toast.error(error as string, { autoClose: 1000 });
    }
  };

  useEffect(() => {
    if (commonCourses.length > 0) {
      const courseList = commonCourses.map((course: any) => ({
        value: course.unique_id,
        label: course.title,
      }));

      setCourseDropdown(courseList);
    }
  }, [commonCourses]);

  // useEffect(()=> {
  //   getProfiles()
  // }, [])
  

  const fetchCourseList = async () => {
    const responseData: any = (await service.courseList(
      token
    ));
    if (responseData.status.code == 400 || responseData.status.code == 500) {
      toast.error("Error during fetch course:" + responseData.message, {
        autoClose: 1000,
      });
    } else {
      setCommonCourses(responseData?.data);
    }
  };

  const FetchQuestionType = async () => {
    try {
      const response: any = (await callApi(
        `admin_app/questiontype/lists/`,
        "GET",
        "",
        token
      ));
      if (response?.status?.code === 200) {
        let new_list = response?.data?.map((item: any) => ({
          value: item?.uuid,
          label: item?.name,
        }));
        if (new_list) {
          setQuestionDropdown(new_list);
        }
      } else {
        toast.error("Failed to fetch question types", { autoClose: 1500 });
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : String(error), {
        autoClose: 1500,
      });
    }
  };

  const FetchStaffList = async () => {
    const data: any = {
      page: 1,
      limit: 10000,
    };
    try {
      const response: any = (await service.StaffSearchList(
        data,
        token
      ));
      if (response.status.code == 400 || response.status.code == 500) {
        toast.error("Error fetching staff:" + response.message, {
          autoClose: 1000,
        });
      } else if (response.status.code === 200 || response.status.code === 201) {
        if (response?.data) {
          const new_list = response?.data?.map((item: any) => ({
            value: item.email,
            label: item.username,
          }));
          setStaffList(new_list);
        }
      }
    } catch (error) {
      toast.error("Error fetching  staff:" + error, { autoClose: 1000 });
    }
  };

  const getProfiles = async () => {
    try {
      const response: any = await getProfile({ token }).unwrap();
      if (response.status?.code === 200) {
        // dispatch(setDetail(response?.data))
        // dispatch(setUserData({
        //   profile_pic: response.data.profile_pic,
        //   profile_pic_horizontal:response.data.profile_pic_horizontal,
        //   profile_pic_round:response.data.profile_pic_round,
        //   first_name: response.data.first_name,
        //   last_name: response.data.last_name,
        //   accessable_modules: response.data.accessable_modules
        // }));
      }
    } catch (error) {
      toast.error(error as string, { autoClose: 1000 });
    }
  };


  useEffect(() => {
    if (token) {
      if (role_name !== 'student') {
        fetchCourseList();
        FetchQuestionType();
        FetchStaffList();
        handleProgramOutcomes();
        handleCourseOutcomes();
        handleBlooms();
      }
      getProfiles();
    }
  }, [token]);

  return (
    <MyContext.Provider
      value={{
        showAImodal,
        setShowAImodal,
        notFoundModal,
        variantAImodal,
        setVariantAImodal,
        setNotFoundModal,
        notFoundDetails,
        setNotFoundDetails,
        commonCourses,
        courseDropdown,
        questionDropdown,
        sideBarResize,
        setSideBarResize,
        staffList,
        setStaffList,
        poList,
        coList,
        blooms,
        questionDetailModal,
        setQuestionDetailModal,
        questionDetail, 
        setQuestionDetail,
    

      }}
    >
      {children}
    </MyContext.Provider>
  );
};

// Custom hook to use context
const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};

export { MyContextProvider, useMyContext };
