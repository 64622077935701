import React, { useEffect, useRef } from 'react';

// Declare the global WirisPlugin object that will be loaded by the script
declare global {
  interface Window {
    WirisPlugin: any;
  }
}

const MathTypeEditor: React.FC = () => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);

  // Load the Wiris plugin script when the component mounts
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/node_modules/@wiris/mathtype-generic/wirisplugin-generic.js';
    script.async = true;

    script.onload = () => {
      initializeMathType();
    };

    script.onerror = () => {
      console.error('Failed to load Wiris plugin.');
    };

    document.body.appendChild(script);
  }, []);

  // Initialize MathType editor once the script is loaded
  const initializeMathType = () => {
    if (window.WirisPlugin) {
      const genericIntegrationProperties = {
        target: editorRef.current,
        toolbar: toolbarRef.current,
      };

      const genericIntegrationInstance = new window.WirisPlugin.GenericIntegration(
        genericIntegrationProperties
      );
      genericIntegrationInstance.init();
      genericIntegrationInstance.listeners.fire('onTargetReady', {});
    } else {
      console.error('WirisPlugin is not available on window.');
    }
  };

  // const getMathContent = () => {
  //   if (window.WirisPlugin && editorRef.current) {
  //     const htmlContent = editorRef.current.innerHTML;
  //     const mathMLContent = window.WirisPlugin.Parser.endParse(htmlContent);
  //     return mathMLContent;
  //   }
  // };

  // const setMathContent = (mathML: string) => {
  //   if (window.WirisPlugin && editorRef.current) {
  //     const parsedHTML = window.WirisPlugin.Parser.initParse(mathML);
  //     editorRef.current.innerHTML = parsedHTML;
  //   }
  // };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Wiris MathType Editor</h2>

      {/* Toolbar for the MathType editor */}
      <div ref={toolbarRef} className="toolbar mb-4"></div>

      {/* Editable area where MathType is initialized */}
      <div
        ref={editorRef}
        // id="htmlEditor"
        className="w-full p-4 border border-gray-300 rounded-md mb-4"
        contentEditable="true"
      >
        Try me!
      </div>

      {/* Button to get MathML content */}
      {/* <button
        onClick={getMathContent}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
      >
        Get Math Content
      </button>

      <button
        onClick={() =>
          setMathContent('<math><msqrt><mo>x</mo></msqrt></math>')
        }
        className="ml-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500"
      >
        Set Math Content
      </button> */}
    </div>
  );
};

export default MathTypeEditor;
