import React, { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import LogoGIF from "./../../assets/gif/greatify.gif";

interface LoaderProps {
    className?: string;
    text?: string | string[];
    variant?: string;
    customStyles?: string;
}

// Utility function to shuffle an array
const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

export const Loader: React.FC<LoaderProps> = ({ variant, className = "", text = "Loading....", customStyles }) => {
    const [currentText, setCurrentText] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (typeof text === "string") {
            setCurrentText(text);
        } else if (Array.isArray(text) && text.length > 0) {
            const shuffledText = shuffleArray([...text]); // Shuffle the array each time the loader is displayed
            let index = 0;
            setCurrentText(shuffledText[index]);

            const intervalId = setInterval(() => {
                index = (index + 1) % shuffledText.length;
                setCurrentText(shuffledText[index]);
            }, 4000);

            return () => clearInterval(intervalId);
        }
    }, [text]);

    return (
        <div className={`fixed inset-0 flex justify-center  items-center z-[1000] bg-GreatifyGreen-50/50  ${className}`}>
            <div className={`z-10 flex flex-col gap-4 justify-center items-center ${variant === "modal" ? `bg-white  shadow-small ${customStyles ? customStyles : "w-[500px] p-12"} h-auto  rounded-xl text-center ` : ""} `}>
                <img src={LogoGIF} className="w-[100px] h-[100px]" alt="Loading" />
                {currentText && <p className={`ml-4 text-lg font-medium text-GreatifyGreen-600`}>{currentText}</p>}
            </div>
        </div>
    );
};
