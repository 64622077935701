import React, { useState, useEffect } from "react";
import { ChooseFromQuestionBank } from "./choose";
import { UserDataComponent } from "../../../UserData";
import SlideOver from "../../../modals/slideover";
import { StyledButton } from "../../Buttons/StyledButton";

const initialData = JSON.parse(
  '{"register_no":"8","header":{"exam_name":"JS","exam_title":"first semester","semester":"first semester","month":"January","year":"2024","exam_hours":"00","max_marks":"00"},"subject":{"code":"JS001","name":"Javascript","sub_title":"DESC"},"notes":"NOte","part_count":"1","co_po":0,"blooms":0,"questionPaperCode":"GRT001","sections":[{"title":"Part 1","question_type":"QTDES001","description":"DESC","mark":100,"mark_per_question":"10","question_count":"10","either":0,"questions":[{"marks":"10","question":"","question_id":"q1"},{"marks":"10","question":"","question_id":"q2"},{"marks":"10","question":"","question_id":"q3"},{"marks":"10","question":"","question_id":"q4"},{"marks":"10","question":"","question_id":"q5"},{"marks":"10","question":"","question_id":"q6"},{"marks":"10","question":"","question_id":"q7"},{"marks":"10","question":"","question_id":"q8"},{"marks":"10","question":"","question_id":"q9"},{"marks":"10","question":"","question_id":"q10"}],"descriptive_type":"DEFAULT"}]}'
);

export const CustomEditor = ({ questionPaper = "" }: any) => {
  const [paperData, setPaperData] = useState(initialData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<
    number | null
  >(null);
  const [currentQType, setCurrentQType] = useState<any>({});
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number | null>(
    null
  );
  const [currentSubPart, setCurrentSubPart] = useState<string | null>(null);
  const [focusedQuestion, setFocusedQuestion] = useState<{
    sectionIndex: number;
    questionIndex: number;
    subPart: string | null;
  } | null>(null);
  const [showBalloon, setShowBalloon] = useState(false);

  const { token } = UserDataComponent();

  useEffect(()=>{
  },[paperData])

  const handleQuestionAction = (
    action: string,
    questionType: any,
    sectionIndex: number,
    questionIndex: number,
    subPart: string | null = null
  ) => {
    setCurrentSectionIndex(sectionIndex);
    setCurrentQuestionIndex(questionIndex);
    setCurrentQType(questionType);
    setCurrentSubPart(subPart);
    setBulkSelect(action === "bulk");
    setIsModalOpen(true);
  };

  const updateQuestionList = () => {
    if (currentSectionIndex !== null && selectedQuestions.length > 0) {
      const updatedPaperData = { ...paperData };
      const currentSection = updatedPaperData.sections[currentSectionIndex];
      let updatedQuestions = [...currentSection.questions];
      const questionLimit = parseInt(currentSection.question_count);

      if (bulkSelect) {
        let startIndex = updatedQuestions.findIndex(
          (q) => !q.question && !q.isEitherOr
        );
        if (startIndex === -1) startIndex = updatedQuestions.length;

        selectedQuestions.forEach((newQuestion, index) => {
          const targetIndex = startIndex + index;
          if (targetIndex < questionLimit) {
            if (targetIndex < updatedQuestions.length) {
              updatedQuestions[targetIndex] = {
                ...updatedQuestions[targetIndex],
                question: newQuestion.question_name,
                question_id: newQuestion.uuid,
              };
            } else {
              updatedQuestions.push({
                marks: currentSection.mark_per_question,
                question: newQuestion.question_name,
                question_id: newQuestion.uuid,
              });
            }
          }
        });
      } else if (currentQuestionIndex !== null) {
        const currentQuestion = updatedQuestions[currentQuestionIndex];
        if (currentQuestion.isEitherOr && currentSubPart) {
          const subQuestionIndex = currentSubPart === "a" ? 0 : 1;
          currentQuestion.subQuestions[subQuestionIndex] = {
            ...currentQuestion.subQuestions[subQuestionIndex],
            content: selectedQuestions[0].question_name,
            question_id: selectedQuestions[0].uuid,
          };
        } else {
          updatedQuestions[currentQuestionIndex] = {
            ...currentQuestion,
            question: selectedQuestions[0].question_name,
            question_id: selectedQuestions[0].uuid,
          };
        }
      }

      updatedPaperData.sections[currentSectionIndex].questions =
        updatedQuestions;
      setPaperData(updatedPaperData);
      setIsModalOpen(false);
      setSelectedQuestions([]);
      setCurrentSubPart(null);
    }
  };

  const calculateAvailableSlots = () => {
    if (currentSectionIndex !== null) {
      const currentSection = paperData.sections[currentSectionIndex];
      const questionLimit = parseInt(currentSection.question_count);
      const filledQuestions = currentSection.questions.reduce(
        (count: number, q: any) => {
          if (q.isEitherOr) {
            return (
              count +
              (q.subQuestions[0].content ? 1 : 0) +
              (q.subQuestions[1].content ? 1 : 0)
            );
          }
          return count + (q.question ? 1 : 0);
        },
        0
      );
      return questionLimit - filledQuestions;
    }
    return 0;
  };

  const toggleEitherOr = (sectionIndex: number, questionIndex: number) => {
    const updatedPaperData = { ...paperData };
    const question =
      updatedPaperData.sections[sectionIndex].questions[questionIndex];

    if (!question.isEitherOr) {
      question.isEitherOr = true;
      question.subQuestions = [
        {
          part: "a",
          content: question.question || "",
          question_id: question.question_id || "",
        },
        { part: "b", content: "", question_id: "" },
      ];
      delete question.question;
      delete question.question_id;
    } else {
      question.isEitherOr = false;
      question.question = question.subQuestions[0].content || "";
      question.question_id = question.subQuestions[0].question_id || "";
      delete question.subQuestions;
    }

    setPaperData(updatedPaperData);
  };

  const handleQuestionClick = (
    sectionIndex: number,
    questionIndex: number,
    subPart: string | null,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    setFocusedQuestion({ sectionIndex, questionIndex, subPart });
    setShowBalloon(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        focusedQuestion &&
        !(event.target as HTMLElement).closest(".question-part")
      ) {
        setShowBalloon(false);
        setFocusedQuestion(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [focusedQuestion]);

  const renderQuestionActions = () => {
    if (!focusedQuestion || !showBalloon) {
      return null;
    }

    const { sectionIndex, questionIndex, subPart } = focusedQuestion;
    const question = paperData.sections[sectionIndex].questions[questionIndex];
    const isEitherOr = question.isEitherOr;
    const content = isEitherOr
      ? subPart === "a"
        ? question.subQuestions[0].content
        : question.subQuestions[1].content
      : question.question;

    return (
      <div className="absolute -top-2 left-0 transform -translate-y-full h-8 bg-white border border-GreatifyNeutral-300 shadow-lg rounded-[4px] px-2 z-[9999] flex space-x-2 ">
        <button
          onClick={() =>
            handleQuestionAction(
              content ? "replace" : "add",
              paperData.sections[sectionIndex].question_type,
              sectionIndex,
              questionIndex,
              subPart
            )
          }
          className="text-GreatifyPurple-500   hover:text-GreatifyPurple-700 font-yellix text-sm font-medium "
        >
          {content ? "Replace" : "Add"}
        </button>

        <div className="w-[1px] h-auto bg-GreatifyNeutral-300 " />

        {isEitherOr && (
          <button
            onClick={() => toggleEitherOr(sectionIndex, questionIndex)}
            className="text-GreatifyPurple-500  hover:text-GreatifyPurple-700 font-yellix text-sm font-medium  "
          >
            Remove Either/Or
          </button>
        )}

        {!isEitherOr && (
          <button
            onClick={() => toggleEitherOr(sectionIndex, questionIndex)}
            className="text-GreatifyPurple-500  hover:text-GreatifyPurple-700 font-yellix text-sm font-medium"
          >
            Either/Or
          </button>
        )}

        <div
          className="absolute -bottom-[10px] left-1 w-0 h-0 
          border-l-[10px] border-l-transparent
          border-r-[10px] border-r-transparent
          border-t-[10px] border-t-gray-300"
        ></div>
      </div>
    );
  };

  return (
    <div
      className="p-16 relative max-w-4xl mx-auto bg-white"
      style={{ fontFamily: "Times New Roman, serif" }}
    >
      <div className="text-center mb-4">
        <h1 className="text-xl font-bold">{`${paperData.header.exam_name}, ${paperData.header.month} ${paperData.header.year}`}</h1>
        <h2 className="text-lg">{paperData.header.exam_title}</h2>
        <h2 className="text-lg font-bold mt-4">{`${paperData.subject.name} - ${paperData.subject.code}`}</h2>
        <p className="italic">{paperData.subject.sub_title}</p>
      </div>

      <div className="mb-6 text-center">
        <p className="font-bold">{paperData.notes}</p>
      </div>

      {paperData.sections.map((section: any, sectionIndex: number) => (
        <div key={sectionIndex} className="mb-6">
          <div className="w-fit mx-auto mb-2">
            <h3 className="font-bold text-center">{`${section.title} (${section.question_count} x ${section.mark_per_question} = ${section.mark})`}</h3>
            <p className="italic mb-4 text-center">{section.description}</p>
          </div>

          <table className="w-full">
            <thead>
              <tr className="">
                <th className="w-3/4 text-sm text-left">
                  Question
                  <span
                    onClick={() =>
                      handleQuestionAction(
                        "bulk",
                        section.question_type,
                        sectionIndex,
                        0
                      )
                    }
                    className="px-2 py-1 ml-8 text-blue-500 font-yellix text-sm font-normal cursor-pointer"
                  >
                    Bulk Add Questions
                  </span>
                </th>
                <th className="w-1/12 text-sm text-center">Marks</th>
                <th className="w-1/12 text-sm text-center">BL</th>
                <th className="w-1/12 text-sm text-center">CO</th>
                <th className="w-1/12 text-sm text-center">PO</th>
              </tr>
            </thead>
            <tbody>
              {section.questions.map((question: any, questionIndex: number) => (
                <tr key={questionIndex}>
                  <td className="py-2">
                    <div className="question-container relative flex items-start">
                      <span className="mr-2">{questionIndex + 1}.</span>
                      <div className="flex-grow">
                        {question.isEitherOr ? (
                          <div className="space-y-2">
                            <div
                              className={`question-part relative flex items-start cursor-pointer  rounded`}
                              onClick={(e) =>
                                handleQuestionClick(
                                  sectionIndex,
                                  questionIndex,
                                  "a",
                                  e
                                )
                              }
                            >
                              {focusedQuestion?.sectionIndex === sectionIndex &&
                                focusedQuestion?.questionIndex ===
                                  questionIndex &&
                                focusedQuestion?.subPart === "a" &&
                                renderQuestionActions()}
                              <span className="mr-2">a)</span>
                              <p className="flex-grow">
                                {question?.subQuestions[0]?.content ||
                                  "No question added yet."}
                              </p>
                            </div>
                            <div className="flex justify-center items-center">
                              <span className="px-2 font-bold">OR</span>
                            </div>
                            <div
                              className={`question-part relative flex items-start cursor-pointer rounded`}
                              onClick={(e) =>
                                handleQuestionClick(
                                  sectionIndex,
                                  questionIndex,
                                  "b",
                                  e
                                )
                              }
                            >
                              {focusedQuestion?.sectionIndex === sectionIndex &&
                                focusedQuestion?.questionIndex ===
                                  questionIndex &&
                                focusedQuestion?.subPart === "b" &&
                                renderQuestionActions()}
                              <span className="mr-2">b)</span>
                              <p className="flex-grow">
                                {question.subQuestions[1].content ||
                                  "No question added yet."}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`question-part relative flex items-start cursor-pointer  rounded `}
                            onClick={(e) =>
                              handleQuestionClick(
                                sectionIndex,
                                questionIndex,
                                null,
                                e
                              )
                            }
                          >
                            {focusedQuestion?.sectionIndex === sectionIndex &&
                              focusedQuestion?.questionIndex ===
                                questionIndex &&
                              focusedQuestion?.subPart === null &&
                              renderQuestionActions()}
                            <p className="flex-grow">
                              {question.question || "No question added yet."}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="text-center text-sm">{question.marks}</td>
                  <td className="text-center text-sm">1</td>
                  <td className="text-center text-sm">1</td>
                  <td className="text-center text-sm">1</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}

      <div className="flex justify-between p-6 border-t">
        <StyledButton onClick={updateQuestionList}>
          Submit Selected Questions
        </StyledButton>
      </div>

      {/* SlideOver Modal */}
      <SlideOver
        onModalSubmit={updateQuestionList}
        modalTitle={"Choose from Question Bank"}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedQuestions([]);
          setCurrentSubPart(null);
        }}
      >
        <ChooseFromQuestionBank
          qpType={questionPaper.tag}
          questionType={currentQType}
          partData={
            currentSectionIndex !== null
              ? paperData.sections[currentSectionIndex]
              : null
          }
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          questionSelectedItems={paperData.sections
            .flatMap((s: any) =>
              s.questions.flatMap((q: any) =>
                q.isEitherOr
                  ? [
                      q.subQuestions[0].question_id,
                      q.subQuestions[1].question_id,
                    ]
                  : q.question_id
              )
            )
            .filter(Boolean)}
          bulkSelect={bulkSelect}
          availableSlots={calculateAvailableSlots()}
        />
      </SlideOver>
    </div>
  );
};
