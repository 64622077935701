import * as Sentry from "@sentry/browser";
import { browserTracingIntegration, replayIntegration } from "@sentry/browser";
if (process.env.NODE_ENV !== 'development') {
  // Initialize Sentry
  Sentry.init({
    // Your project's DSN
    dsn: process.env.SENTRY_DSN,
    
    // Add release identifier
    release: process.env.SENTRY_RELEASE || '1.0.0',
    
    // Add integrations using the new method
    integrations: [
      browserTracingIntegration(),
      replayIntegration(),
    ],
    
    // Performance Monitoring settings
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    
    // Session Replay settings
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    
    // Environment setting
    environment: process.env.NODE_ENV,
    
    // Recommended additional settings
    enabled: true,
    debug: process.env.NODE_ENV !== 'production',
    
    // Optional: Configure error sampling
    sampleRate: 1.0,
    
    // Optional: Maximum number of breadcrumbs
    maxBreadcrumbs: 50,
    
    // Let Sentry handle default integrations
    defaultIntegrations: undefined  // This will allow Sentry to use recommended defaults
  });
}