import { App } from '../utils/constants';
import { AppRouter, MainRouter } from '../routers';
import { getSubdomains } from '../utils/constants';

export const Apps: App[] = [
    {
        subdomain: '',
        app: AppRouter,
        main: true,
    },
    {
        subdomain: getSubdomains(window.location.hostname),
        app: MainRouter,
        main: false
    }
];

export const getApp = () => {
    const subdomain = getSubdomains(window.location.hostname);
    const main = Apps.find((app) => app.main);
    if(!main) throw new Error("Must have main app");
    if(subdomain === '') return main;
    const app = Apps.find(app => subdomain === app.subdomain);
    if(!app || app == undefined) return main;
    return app;
}