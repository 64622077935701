import React from 'react';
interface Props {
    message:string;
    children?:any;
}

export const Tooltip: React.FC<Props> = ({ message='', children }) => {
    return (
        <div className="group relative flex items-center whitespace-nowrap justify-start max-w-full">
            {children}
            <span className="flex justify-start absolute bottom-4 right-0 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{message}</span>
        </div>
    );
}