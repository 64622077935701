import { useState, useEffect } from "react";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { $insertNodes } from "lexical";

interface Props {
    initialHtml?: string;
    onHtmlChanged: (html: string) => void;
}

const HtmlPlugin = ({ initialHtml, onHtmlChanged }: Props) => {
    const [editor] = useLexicalComposerContext();
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        if (!initialHtml || !isFirstRender) return;
        setIsFirstRender(false);
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialHtml, "text/html");
            const nodes = $generateNodesFromDOM(editor, dom);
            $insertNodes(nodes);
        });
    }, []);

    const generateHtmlWithMathML = (editor: any) => {
   
        const html = $generateHtmlFromNodes(editor);
  
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const mathElements = doc.getElementsByTagName('math');
        
        for (let i = 0; i < mathElements.length; i++) {
            const mathElement = mathElements[i];
            mathElement.setAttribute('xmlns', 'http://www.w3.org/1998/Math/MathML');
        }

        return doc.body.innerHTML;
    };

    return (
        <OnChangePlugin
            onChange={(editorState) => {
                editorState.read(() => {
                    onHtmlChanged(generateHtmlWithMathML(editor));
                });
            }}
        />
    );
};

export default HtmlPlugin;