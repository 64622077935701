export const getSubdomains = (location: string) => {
    const locationParts  = location.split(".");
    let sliceFill = -2;
    const islocalhost =  locationParts.slice(-1)[0] === 'localhost';
    if(islocalhost) sliceFill = -1;
    return locationParts.slice(0, sliceFill).join("");
}

export const strip = (url: string) => {
    const fragments = url.split('.');
    const last = fragments.pop();
    try {
        const instance = new URL(url);
        return `${instance.protocol}//${fragments.pop()}.${last}`;
    } catch (_) {
        return `${fragments.pop()}.${last}`;
    }
}

// Remove the Apps array from here and create a new types file
export interface App {
    subdomain: string;
    app: React.FC;
    main: boolean;
}