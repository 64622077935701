import React from 'react';
interface Props {
    children?: React.ReactNode;
    type?: 'submit' | 'button' | 'reset';
    style?: string;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }

  export const  StyledButton: React.FC<Props> = ({ style='default', className='text-GreatifyNeutral-500 font-bold', type='button', onClick, children }) => {

    return (
        <React.Fragment>
            { style == "primary" ? (
            <button
                className={`flex text-white font-semibold text-nowrap items-center px-3 py-2 text-sm  border border-green-600 cursor-pointer justify-between gap-2 rounded-lg bg-custom-gradient hover:from-[#00C13D] hover:to-[#00DC46] transition duration-300 ${className} `}
                type={type}
                onClick={onClick}
            >
                {children}
            </button>
            ) : 
            style == "danger" ? (
                <button
                className={`flex items-center gap-1 text-red-500 text-nowrap bg-[#FAFAFA] border border-[#E4E4E7] px-3 py-2.5 rounded-lg font-yellixBold text-sm ${className}`}
                type={type}
                onClick={onClick}
            >
                {children}
            </button>
            ) : 
            style == "default" ? (
                <button
                    className={`flex items-center gap-1 text-nowrap bg-[#FAFAFA] border border-[#E4E4E7] px-3 py-2.5 rounded-lg font-yellixBold text-sm ${className}`}
                    type={type}
                    onClick={onClick}
                >
                    {children}
                </button>
            ) : ''}
       
        </React.Fragment>
    )
  }