import { getSubdomains } from "./constants";
import { Apps } from '../config/appConfig';

export const getApp = () => {
    const subdomain = getSubdomains(window.location.hostname);
    const main = Apps.find((app) => app.main);
    if(!main) throw new Error("Must have main app");
    if(subdomain === '') return main;
    const app = Apps.find(app => subdomain === app.subdomain);
    if(!app || app == undefined) return main;
    return app;
}

export function formatDate(dateString:string,format="lookup") {
  return new Date(dateString).toLocaleString(format)
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function convertTo12Hour(time: string): string {
    if(time){
    let [hours, minutes] = time?.split(':');
    let hours12 = ((+hours % 12) || 12).toString().padStart(2, '0');
    let period = +hours >= 12 ? 'PM' : 'AM';
    return `${hours12}:${minutes} ${period}`;
    }
    return ""
}

export const toHoursAndMinutes = (totalMinutes:string)  => {
  const hours = Math.floor(parseInt(totalMinutes) / 60);
  const minutes = parseInt(totalMinutes) % 60;
  return `${hours>0 ? `${hours} Hours`:''} ${minutes > 0 ? ` ${minutes} Mins` : ""}`;
}

export const minutesToTime = (minutes:string) => {
  const totMinutes = parseInt(minutes);
  const hh = Math.floor(totMinutes /60);
  const mm = totMinutes % 60;
  return (hh? hh.toString().padStart(2,'0'):'00')+':'+(mm?mm.toString().padStart(2,'0'):'00');
}

/**
 * Function to convert a given number into words.
 * @param {number} - The number to be converted into words.
 * @returns {string} - The word representation of the given number.
 */
export const transToWords = (value:number=0): string => {
  // Arrays to hold words for single-digit, double-digit, and below-hundred numbers
  const single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  const double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
  const below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  const n = value;
  let word = "";
  if (n < 10) {
      word = single_digit[n] + ' ';
  } else if (n < 20) {
      word = double_digit[n - 10] + ' ';
  } else if (n < 100) {
      let rem = transToWords(n % 10);
      word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;
  } else if (n < 1000) {
      word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + transToWords(n % 100);
  }
  return word;
}

export const convertToWord = (n:number) => {
  if (n < 0)
      return false;
  
  if (n === 0) return 'Zero';
  
  // Get the result by translating the given number
  let result = transToWords(n);
  return result.trim();
}

export const formatCustomerDateFormat = (dateString: string, format:any = {}): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = format;

  // Format using Intl.DateTimeFormat
  let formattedDate = new Intl.DateTimeFormat('en-US', options)?.format(date);

  // Manually replace components to match the desired format
  formattedDate = formattedDate.replace(/, /g, ' ');
  formattedDate = formattedDate.replace(' AM', ' AM').replace(' PM', ' PM');

  return formattedDate;
}

