import EquationEditor from "../../fields/EquationEditor";
import React, { useState } from "react";

const TestingEquation = () => {
  const [question, setQuestion] = useState<string>("");

  // This function will now receive the content directly
  const handleInputChange = (content: string) => {
    setQuestion(content); // Set the content directly
  };

  return (
    <div className="w-[70%] mx-auto">
      <div className="">
        <EquationEditor
          id="question_name"
          name="question_name"
          value={question} // Pass the question value to the EquationEditor
          labelName="Question"
          onChange={handleInputChange} // Handle content change directly
          rows={3}
        />
      </div>
      <p className="mt-8">Output: {question}</p>
    </div>
  );
};

export default TestingEquation;
