import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApp } from "./utils/helpers";
import "./index.css";
import { MyContextProvider } from "./context/contextProvider";
import posthog from 'posthog-js';
import initPostHogWithRotation from './posthog-rotation';
import "./sentry";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { messages as enMessages } from '../src/locales/en/messages'
import { LanguageContextProvider } from "./LanguageContext";
import SupportedLannguages from "../language-translation/SupportedLanguages";
// import {useAwsSecrets} from "./secrets";
// import { messages as hiMessages } from '../src/locales/hi/messages'
// import { messages as arMessages } from './locales/ar/messages'
// import { messages as taMessages } from '../src/locales/ta/messages'
// import registerServiceWorker from './registerServiceWorker';

// function SecretsLoader() {
//   const secrets = AwsComponent();
//   console.log('AWS Component:', secrets);
//   return null;
// }

    // const secrets = AwsComponent();
    // console.log('AWS Component:', useAwsSecrets);

i18n.load({
  en: enMessages,
})
const loadMessages = async (locale:string) => {
  try {
    const messages = await import(`../src/locales/${locale}/messages.ts`);
    i18n.load({
      [locale]: messages.messages,      
    })
     messages.messages;
  } catch (error) {
    console.error(`Failed to load ${locale} messages:`, error);
    return {};
  }
};

SupportedLannguages.map( ({lang, name}) => {
  loadMessages(lang);
})

i18n.activate("en");

// Initialize PostHog
if (process.env.NODE_ENV !== 'development') {
  // Initialize PostHog with key rotation
  const rotationManager = initPostHogWithRotation();
  // Initialize with first key and enable auto-rotation
  rotationManager.init().catch((error: any) => {
    console.error('Failed to initialize PostHog:', error);
    // Rotation manager will automatically retry with next key
  });

  // Set up periodic key rotation (e.g. every 24 hours)
  setInterval(() => {
    rotationManager.rotate().catch(console.error);
  }, 24 * 60 * 60 * 1000);

  // Monitor initialization status
  const checkInitStatus = setInterval(() => {
    const status = rotationManager.getCurrentKeyInfo();
    if (status.initialized) {
      clearInterval(checkInitStatus);
    }
  }, 1000);
}

const App = React.memo(() => {
  // const { secrets, loading, error } = useAwsSecrets()
  // console.log(secrets);
  const CurrentApp = getApp();
  const App = CurrentApp["app"];
  // Add PostHog page tracking
  if (process.env.NODE_ENV !== 'development') {
    const user = store.getState().user?.user;
    React.useEffect(() => {
      // Capture page view with user info  
      posthog.capture('$pageview', {
        username: user?.user?.first_name,
        subdomain:user?.subdomain,
        user_id: user?.user?.user_id
      })
    }, [location.pathname, user])

    // Identify user when logged in
    React.useEffect(() => {
      if (user?.user?.user_id) {
        posthog.identify(user?.user?.user_id, {
          username: user?.user?.first_name,
          email: user?.user?.email
        });
      }
    }, [user]);
  }

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MyContextProvider>
            <BrowserRouter future={{ 
              v7_startTransition: true,
              v7_relativeSplatPath: true 
            }}>
                <I18nProvider i18n={i18n}>
                 <LanguageContextProvider>
                    <App />
                  </LanguageContextProvider>
                </I18nProvider>
                <ToastContainer />
            </BrowserRouter>
          </MyContextProvider>
        </PersistGate>
      </Provider>
    </>
  );
});

const rootElement = document.getElementById("root") as HTMLElement;
ReactDOM.createRoot(rootElement).render(
    <App />
);

// registerServiceWorker();