import { useSelector } from 'react-redux';
import { RootState } from '@reducers/reducer';
import LogoMark from "../assets/Logo mark.svg";

export const UserDataComponent = () => {
  const userData = useSelector((state: RootState) => state.user.user);
  
  const user = userData?.user ?? {};
  const token = user.token ?? '';
  const role_name = user.role_name ?? '';
  const username = user.username ?? '';
  const first_name = user.first_name ?? '';
  const organization_category = user.organization_type ?? '';
  const user_module_list = user.modules ?? [];
  const userID = user.user_id ?? '';
  const Image = user.profile_pic ?? LogoMark;
  const profileImage = Image || LogoMark;
  const profileHorizontal=user?.profile_pic_horizontal ?? LogoMark
  const profileRound=user?.profile_pic_round ?? LogoMark
  const organization_id = user.organization_id ?? '';
  const organization_name = user.organization_name ?? '';
  const organisation_website = user.organisation_website ?? '';
  const obe_configuration = user.obe_configuration ?? '';
  const login_uuid = user.uuid ?? '';
  const accessableModules = user?.accessable_modules;
  
  return {
    userData,
    role_name,
    token,
    username,
    first_name,
    organization_category,
    user_module_list,
    userID,
    profileImage,
    profileHorizontal,
    profileRound,
    organization_id,
    organization_name,
    organisation_website,
    obe_configuration,
    login_uuid,
    accessableModules
  };
};