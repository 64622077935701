import axios from "axios";
import { getSubdomains } from "../utils/constants";
import api from './useAxiosInterceptor';

export async function callApi(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'POST',
    body: any = {},
    token?: string,
    hasAttachment: boolean = false, // Changed to boolean type
    signal?: AbortSignal
): Promise<ResponseType> {
    try {
        const subdomain = getSubdomains(window.location.hostname);
        const headers: Record<string, string> = {
            // Fixed typo in "multipart/form-data"
            "Content-Type": hasAttachment ? "multipart/form-data" : "application/json",
            ...(token && { 'Authorization': `Token ${token}` }), // Changed to Bearer auth
            ...(subdomain && { 'X-Database-Choice': subdomain })
        };
        const config: any = {
            url,
            method,
            params: method === 'GET' ? body : undefined,
            data: method === 'GET' ? undefined : body,
            headers,
            maxRedirects: 0,
            validateStatus: (status: number) => {
                return (status >= 200 && status < 400) || status === 301;
            },
            signal
        };
        // First API call attempt
        const response = await api(config);

        // Handle 301 redirect
        if (response.status === 301 && response.headers?.location) {
            const redirectConfig = {
                ...config,
                url: response.headers.location
            };
            const redirectResponse = await api(redirectConfig);
            return redirectResponse.data;
        }

        return response.data;

    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            // Handle cancelled requests
            if (error.code === 'ERR_CANCELED') {
                throw new Error('Request was cancelled');
            }

            if (error.response) {
                // Handle 401 Unauthorized
                if (error.response.status === 401) {
                    return {
                        status: {
                            code: 401,
                            message: 'Unauthorized'
                        }
                    } as unknown as ResponseType;
                }
                // Return error response data if available
                if (error.response.data) {
                    return error.response.data;
                }
            }

            // Handle network errors
            if (error.request) {
                throw new Error('No response received from server');
            }
        }

        // Handle other errors
        throw error;
    }
}

// export async function callApi(
//     url: string,
//     method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'POST', 
//     body: any = {},
//     token?: string,
//     hasAttachment: any = 0,
//     signal?: AbortSignal
// ): Promise<ResponseType> {
//     try {
//         const subdomain = getSubdomains(window.location.hostname);
//         const completeBody = body;
//         const headers = {
//             "Content-Type": hasAttachment ? "mutipart/form-data" : "application/json",
//             ...(token && { 'Authorization': `Token ${token}` }),
//             ...(subdomain && { 'X-Database-Choice': subdomain })
//         };

//         // First API call attempt
//         const response = await api({
//             url,
//             method,
//             params: method === 'GET' ? body : undefined,
//             data: method !== 'GET' ? completeBody : undefined,
//             headers,
//             maxRedirects: 0, // Don't auto-follow redirects
//             validateStatus: (status) => {
//                 return status >= 200 && status < 400 || status === 301;
//             },
//             signal
//         });

//         // If we get a 301 redirect response
//         if (response.status === 301 && response.headers.location) {
//             // Second API call to follow redirect
//             const redirectResponse = await api({
//                 url: response.headers.location,
//                 method,
//                 params: method === 'GET' ? body : undefined,
//                 data: method !== 'GET' ? completeBody : undefined,
//                 headers,
//                 maxRedirects: 0,
//                 signal
//             });
//             return redirectResponse.data;
//         }

//         // For successful 200 responses
//         const responseData: ResponseType = response.data;
//         return responseData;

//     } catch (error: any) {
//         if (axios.isAxiosError(error)) {
//             if (error.response) {
//                 if (error.response.status === 401) {
//                     return {
//                         status: {
//                             code: 401,
//                             message: 'Unauthorized'
//                         }
//                     } as unknown as ResponseType;
//                 }
//                 if (error.response.data) {
//                     return error.response.data;
//                 }
//             }
//             throw new Error(`Network error: ${error.message}`);
//         } else {
//             throw new Error(`Request failed: ${error.message}`);
//         }
//     }
// }

export const service = {
    // async checkSubDomain(): Promise<ResponseType> {
    //     try {
    //         return callApi('superadmin_app/sub_domain_check/', 'POST');
    //     } catch (error) {
    //         throw new Error(`Error in check subdomain: ${error}`);
    //     }
    // },

    async login(body: Record<string, string>): Promise<ResponseType> {
        try {
            return callApi('user_app/login/', 'POST', body);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async getProfile(token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('user_app/get_self_profile/', 'GET', "", token);
        } catch (error) {
            throw new Error(`Error in get self profile: ${error}`);
        }
    },

    async profileUpdate(data: FormData, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('user_app/profile_self_update/', 'PUT', data, token, true);
        } catch (error) {
            throw new Error(`Error in get self profile: ${error}`);
        }
    },

    async Invite(input: {
        organization_name: string;
        site_name: string;
        email: string;
        organization_type: string;
        modules: {
            module_id: any;
            module_name: string | undefined;
            submodules: any;
        }[];
        onboard_status: string;
    }, token: any): Promise<ResponseType> {
        try {
            return callApi('superadmin_app/onboard_save_data/', 'POST', input, token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async getOrganizationCategory(token: any): Promise<ResponseType> {
        try {
            return callApi('superadmin_app/get_all_customer_category/?type="all', 'GET', '', token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async getOrganizationModule(token: any, type: any): Promise<ResponseType> {
        try {
            return callApi('superadmin_app/list_module/' + type, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async getInvitedOrganizationDetails(type: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/get_all_organization_invites/${type}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async getOnboardedOrganizationDetails(type: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/get_all_organization_onboarded/${type}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async getBlockedOrganizationDetails(type: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/get_all_organization_blocked/${type}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async blockOrganization(body: any, type: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/organization_block_and_unblock/${type}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async unBlockOrganization(body: any, type: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/organization_unblock/${type}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async resendInviteOrganization(uuid: string, token: any): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/organization_resend_invite/${uuid}/`, 'POST', '', token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async onboardOrganization(type: any): Promise<ResponseType> {
        try {
            return callApi('admin_app/onbord_organization_detail/' + type, 'GET');
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async onboardOrganizationDetails(type: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/onboarding_get_by_id/${type}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error : ${error}`);
        }
    },

    async courseList(token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/courses/?page=1&limit=1000000', 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async getCourseList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/courses/`, 'GET', data, token);
            // return callApi(`admin_app/courses/?page=1&limit=100`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createCourse(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/courses/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateCourseByID(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/courses/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCourseListByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/courses/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteCourseByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/courses/delete/`, 'DELETE', uuid, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async userPasswordUpdate(body: Record<string, string>): Promise<ResponseType> {
        try {
            return callApi('admin_app/password_update/', 'POST', body, '');
        } catch (error) {
            throw new Error(`Axios error in check subdomain: ${error}`);
        }
    },

    async userInviteOnboard(body: any): Promise<ResponseType> {

        try {
            return callApi('admin_app/onboard_update/', 'POST', body, "", true);
        } catch (error) {
            throw new Error(`Axios error in check subdomain: ${error}`);
        }
    },

    async userAlreadyPasswordUpdate(body: any): Promise<ResponseType> {
        try {
            return callApi('admin_app/password_already_update_check/', 'POST', body);
        } catch (error) {
            throw new Error(`Axios error in check subdomain: ${error}`);
        }
    },

    async StudentCreate(body: Record<string, string>, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('user_app/student_create/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error in check student create: ${error}`);
        }
    },

    async getStudent(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi('user_app/student/search/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async getStudentDelete(body: { uuid: any; }, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/student_delete/`, 'DELETE', body, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async StudentEdit(uuid: string | null, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/student_edit/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in check student edit: ${error}`);
        }
    },

    async StudentUpdate(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/student_update/${body.uuid}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async StaffSearchList(body: { limit: number; }, token: string): Promise<ResponseType> {
        try {
            return callApi('user_app/staff/search/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async StaffCreate(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi('user_app/staff_save_data/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async StaffDelete(body: { uuid: any; }, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/staff_del/`, 'DELETE', body, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async StaffGetByID(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/staff_get_by_id/${body.uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in check student edit: ${error}`);
        }
    },

    async StaffUpdate(uuid: any, body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/staff_update_data/${uuid}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async QuestionTypeList(token: string): Promise<ResponseType> {
        try {
            return callApi('admin_app/questiontype/lists/', 'GET', {}, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    async QuestionPaperConfigCreate(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam/qp_configuration/save/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error in check subdomain: ${error}`);
        }
    },

    // async QuestionPaperCreateAPI(body: Record<string, string>, token: string): Promise<ResponseType> {
    //     try {
    //         return callApi('admin_app/exam/question_paper/create/', 'POST', body, token);
    //     } catch (error) {
    //         throw new Error(`Error in check subdomain: ${error}`);
    //     }
    // },

    async QuestionPaperConfigEdit(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/qp_configuration/edit/${body.uuid}/`, 'GET', {}, token);
        } catch (error) {
            throw new Error(`Error in loading Question Paper data: ${error}`);
        }
    },

    async QuestionPaperUpdate(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/update_exam_question_paper/${body.uuid}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error in updating the Question Paper data: ${error}`);
        }
    },

    // async unitListApi(body: Record<string, string>, token: string | undefined): Promise<ResponseType> {
    //     try {
    //         return callApi('admin_app/unit/list/', 'POST', body, token);
    //     } catch (error) {
    //         throw new Error(`Error in check Unit list: ${error}`);
    //     }
    // },

    async unitSearch(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/unit/search/', 'POST', data, token);
        } catch (error) {
            throw new Error(`Error in check Unit search: ${error}`);
        }
    },

    async unitCreate(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi('admin_app/unit_save_data/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error in check Unit Create: ${error}`);
        }
    },

    async unitUpdate(body: Record<string, string>, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/unit_update_data/${body.uuid}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error in check Unit Update: ${error}`);
        }
    },

    async unitEdit(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/unit_get_by_id/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in check Unit Edit: ${error}`);
        }
    },

    async unitDelete(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/unit_del/`, 'DELETE', uuid, token);
        } catch (error) {
            throw new Error(`Error in check Unit Delete: ${error}`);
        }
    },

    // async chapterListApi(token: string | undefined): Promise<ResponseType> {
    //     try {
    //         return callApi('admin_app/chapter/list/', 'GET', '', token);
    //     } catch (error) {
    //         throw new Error(`Error in check chapter list: ${error}`);
    //     }
    // },

    async chapterSearch(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/chapter_search/', 'POST', data, token);
        } catch (error) {
            throw new Error(`Error in check chapter search: ${error}`);
        }
    },

    async chapterCreate(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi('admin_app/chapter_save_data/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error in check chapter Create: ${error}`);
        }
    },

    async chapterUpdate(data: any, uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/chapter_update_data/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error in check chapter Update: ${error}`);
        }
    },

    async chapterEdit(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/chapter_get_by_id/${uuid}/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error in check chapter Edit: ${error}`);
        }
    },

    async chapterDelete(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/chapter_del/`, 'DELETE', uuid, token);
        } catch (error) {
            throw new Error(`Error in check chapter delete: ${error}`);
        }
    },

    async staffList(type: string, token: string): Promise<ResponseType> {
        try {
            return callApi('user_app/staff_lists/' + type + '/', 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in staffList: ${error}`);
        }
    },

    async studentList(type: string, token: string): Promise<ResponseType> {
        try {
            return callApi('user_app/student/lists/' + type + '/?limit=200000', 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in studentList: ${error}`);
        }
    },

    async unitList(type: string, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/unit/list/' + type + '/', 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in get unit list: ${error}`);
        }
    },

    async createExam({ data, token }: { data: FormData, token: string }): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam_save_data/', 'POST', data, token, true);
        } catch (error) {
            throw new Error(`Error in createExam: ${error}`);
        }
    },

    async examList(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam/list/', 'POST', data, token);
        } catch (error) {
            throw new Error(`Error in get exam list: ${error}`);
        }
    },

    async getExamById(data: any, type: string | undefined, token: string | undefined, p0?: { signal: AbortSignal; }): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam_get_by_id/' + type, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error in get exam list: ${error}`);
        }
    },

    async examDelete(type: string, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam_del/' + type + '/', 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error in delete exam: ${error}`);
        }
    },

    async assignQuestionPaper(qp_uuid: string, type: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam/question_paper/publish/?question_paper_uuid=' + qp_uuid + '&type=' + type, 'PUT', '', token);
        } catch (error) {
            throw new Error(`Error in assign exam: ${error}`);
        }
    },

    async examScheduleUpdate(dataToPass: {
        exam_id: any;
        remarks: any;
        scheduled_date: string;
        start_time: string;
    }, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam/schedule/update', 'PUT', dataToPass, token);
        } catch (error) {
            throw new Error(`Error in publishing the exam : ${error}`);
        }
    },

    async examPublishUnPublish(type: any, body: { status: number; }, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/publish/unpublish/${type}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error in unpublishing the exam: ${error}`);
        }
    },

    async updateExam(formData: FormData, token: string | undefined, type: any): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam/update/' + type + '/', 'PUT', formData, token, true);
        } catch (error) {
            throw new Error(`Error in updateExam: ${error}`);
        }
    },

    async studentBlukUpload(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi('user_app/student/upload-csv/', 'POST', body, token, true);
        } catch (error) {
            throw new Error(`Error in student bulk upload: ${error}`);
        }
    },

    async getExamResult(filters: { limit: any; page: any; sort: any; }, type: any, token: string, apiType: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/${apiType == "1" ? 'get_exam_results' : 'get_exam_ai_results'}/${type}/?page=${filters.page}&limit=${filters.limit}&sort=${filters.sort}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in exam result loading: ${error}`);
        }
    },

    async getResultDownload(data: { filters: { search: string; }; limit: number; page: number; }, token: string): Promise<ResponseType> {
        try {
            return callApi('teacher_app/get_list_zip_pdf_cron/', 'POST', data, token);
        } catch (error) {
            throw new Error(`Error in result downloading: ${error}`);
        }
    },

    async getReportDownload(uuid: string | undefined, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('report_app/difference_exam_report/' + uuid, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error in result downloading: ${error}`);
        }
    },

    async getQuestionTypeList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi('admin_app/questiontype/search/', 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getQuestionList(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/question_paper_search/', 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async generateQuestionView(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/exam/question_paper/view/', 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async generateAnswerView(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/question_paper/answer_key/view/', 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getStaffList(qp_id: any, course_id: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('user_app/staff/lists/?course_id=' + course_id + '&question_paper_id=' + qp_id, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async sendSelectedStaffList(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi('admin_app/question_paper/share/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getExamQuestionDetails(uuid: any, data: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/question_paper_get_by_id/${uuid}/`, 'GET', {}, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getExamQuestionPapers(uuid: any, data: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/question_paper/${uuid}/?search=${data?.search}&page=${data?.page}&limit=${data?.limit}&author=${data?.author}`, 'GET', {}, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionPaperApproveAndDecline(body: any, uuid: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`admin_app/question_paper_share/approve/${uuid}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteQuestionPaper(uuid: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`admin_app/question_paper_del/${uuid}/`, 'PUT', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionSearchAutoComplete(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi('admin_app/question_search_autocomplete/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionSearchApi(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi('admin_app/question_search/', 'POST', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async dashboardCount(role: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`${role == "super-admin" ? 'superadmin_app/dashboard_count' : 'admin_app/dashboard/count/'}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async widgetDashboardCounts(role: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`${role == "super-admin" ? 'superadmin_app/dashboard_count' : 'admin_app/widget/counts/'}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async widgetQuestionPaperAlloted(token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/widget/question_paper_alloted/', 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async widgetExamsProgress(token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/widget/exam_analysis/', 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async widgetStudentEntrolment(token: string | undefined): Promise<ResponseType> {
        try {
            return callApi('admin_app/widget/course_student_breakdown/', 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async resultPDFTOZIP(uuid: any, name: string, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/get_student_ai_results_pdf_to_zip/?examId=${[uuid]}&name=${name}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async studentAIResult(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/get_student_ai_results/?exam_id=${data.split('to')[0]}&student_id=${data.split('to')[1]}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async comparisonReport(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/get_student_comparison_report/?exam_id=${data.split('to')[0]}&student_id=${data.split('to')[1]}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async examUnpublish(type: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/unpublish/${type}/`, 'PUT', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async addStudentToExam(body: any, type: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/student/mapping/${type}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteStudentFromExam(body: any, type: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/student/mapping/delete/${type}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async displayUsersToExam(exam: any, type: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam_get_by_id/${exam}/?course_id=${type}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async staffAssignToExam(body: any, type: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam_staff_mapping/${type}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async staffDeleteFromExam(body: any, type: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam_staff_mapping_delete/${type}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async reScheduleExam(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`student_app/exam_reschedule/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async handleGraceTime(exam_id: string | undefined, student_id: string, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/attendance_grace_time/`, 'PUT', { 'exam_id': exam_id, 'student_uuid': student_id }, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async aiEvaluate(exam: string | undefined, student: string | undefined, type: string, token: string | undefined): Promise<ResponseType> {
        let apiUrl = `teacher_app/ai_evaluate/?exam_id=${exam}`;
        if (type === '2' && student) {
            apiUrl += `&student_id=${student}`;
        }
        try {
            return callApi(apiUrl, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async studentMark(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/update_mark_student_results/`, 'POST', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getModules(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/module_get_by_user/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createAutomateQP(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/single_click_automate_question_paper_view/${data?.template_id}/?exam_uuid=${data?.exam_id}`, 'GET', "", token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async roleCreate(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/designation_creation/`, 'POST', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async listCreatedRole(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_designation/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getRoleByID(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/DesignationGETByID/${id}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateRole(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/DesignationUpdate/${id}`, 'PUT', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteRole(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/DesignationDelete/${id}`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getHierarchyDetails(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_designation_for_hierarchy/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getExamCourseInfo(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_by_exam_id/${id}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async submitStudentInfo(data: FormData, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam_student_bulk_upload/`, 'POST', data, token, true);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getActiveExam(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/active/list/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionTemplateCreate(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/templates/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionTemplateList(token: string): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/templates/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async DefaultTemplateGetById(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`superadmin_app/templates/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getTemplateList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/questionpapertemplates/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async CreateQuestionPaperTemplate(body: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/questionpapertemplates/create/`, 'POST', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionTemplateGetById(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/questionpapertemplates/${id}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionTemplateDelete(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/questionpapertemplates/${id}/delete/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionTemplateSetAsDefault(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/questionpapertemplates/${id}/default_selection_update/`, 'PUT', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    // async getDefaultTemplate(token: string): Promise<ResponseType> {
    //     try {
    //         return callApi(`admin_app/questionpapertemplates/`, 'GET', '', token);
    //     } catch (error) {
    //         throw new Error(`Error: ${error}`);
    //     }
    // },

    async getDefaultTemplate(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/questionpapertemplates/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getActiveExamData(data: any, token: string | undefined): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/active/list/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getUniqueExamQuestionPaper(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/qp_exam_unique_search/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionPaperApproval(uuid: string | undefined, token: string | undefined, role: any): Promise<ResponseType> {
        try {
            return role == 'scrutinizer' ? callApi(`admin_app/exam/question_paper/approval/${uuid}/`, 'GET', '', token) :
                callApi(`admin_app/question_answer_view/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getQuestionAnswerDetails(uuid: any, token: any): Promise<ResponseType> {
        try {
            return callApi(`admin_app/question_answer_view/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getQuestionPaperApprovalView(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/question_paper/approval/${id}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async sendQuestionPaperApproval(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/question_paper/send_approve/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async displayQuestionsForApproval(qp_id: any, question_id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/question_paper/approvaledit/${qp_id}/${question_id}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getScrutinizerList(course_id: any, question_id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/scrutinizer/lists/?course_id=${course_id}&question_paper_id=${question_id}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateQuestions(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/question_paper/approvalupdate/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateRemoveQuestions(qp_id: any, data: any, token: string): Promise<ResponseType> {
        try {

            return callApi(`admin_app/exam/question_paper/approvalupdate/${qp_id}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async removeQuestions(qp_id: any, question_id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/question_paper/approvalremove/${qp_id}/${question_id}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async questionPaperFinalApproval(body: any, uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/question_paper/final_approval/${uuid}/`, 'PUT', body, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getQuestionFromQuestionPaper(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/question_paper/approvaladd/${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createRegulation(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/regulation/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateRegulation(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/regulation/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getListOfRegulation(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/regulation/list/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getRegulationListByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/regulation/get_by_id/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },
    async regulationDeleteByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/regulation/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createDepartment(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/department/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateDepartment(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/department/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getListOfDepartment(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/department/list/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getDepartmentListByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/department/get_by_id/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async departmentDeleteByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/department/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createGraduate(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/create_graduation/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteGraduate(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/delete_graduation/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getListGraduate(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_graduation/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getGraduateListByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_graduation/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateGraduate(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/update_graduation/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteGraduateByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/delete_graduation/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createProgram(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/programs/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getProgramList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/programs/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async allProgramList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/all_programs/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getProgramById(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/programs/get_by_id/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteProgramByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/programs/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getDepartmentList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/department/list`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getProgramListName(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_program_lists/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateProgramByID(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/programs/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getSpecialisationById(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_program_specializations/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createSpecialisation(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/concentrations/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getSpecialiastionList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/concentrations/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getSpecialisationListByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/concentrations/get_by_id/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateSpecialisationByID(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/concentrations/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteSpecialisationByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/concentrations/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getPublishedExamsAPI(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/publish/list/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },
    async getExamSubmissionReportAPI(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/generate/report/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getOBEConfigurationList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_obe_configurations/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllOBEConfigurationList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_all_obe_configurations/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createOBEConfiguration(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/obe_configuration_save_data/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateObeByID(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/obe_configuration_update_data/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCollegeList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/college/list/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async peoListApi(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/educational-objectives/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createPEO(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/educational-objectives/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getPeoByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/educational-objectives/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getBloomsDomainCategoryList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/domains/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getBloomsDomainList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/domains_for_blooms/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createBloomsConfiguration(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/blooms_taxonomy_levels/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateBloomsByID(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/blooms_taxonomy_levels/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updatePEOById(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/educational-objectives/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deletePeoById(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/educational-objectives/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async poListApi(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/program-outcomes/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createPO(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/program-outcomes/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getPoByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/program-outcomes/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updatePOById(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/program-outcomes/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deletePoById(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/program-outcomes/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async psoListApi(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/specific-outcomes/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createPSO(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/specific-outcomes/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getPsoByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/specific-outcomes/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updatePsoById(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/specific-outcomes/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deletePsoById(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/specific-outcomes/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getBloomsListByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/blooms_taxonomy_levels/${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getOBEByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/obe_configuration_get_by_id/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteOBEByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/obe_configuration_del/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createCourseOutcomeLevel(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/course-outcomes/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCourseOutcomeList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/course-outcomes/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCourseOutcomeByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/course-outcome/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateCourseOutcomeById(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/course-outcome/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteCourseOutcomeByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/course-outcome/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getProgramOutcomeList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/program-outcomes/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createProgramLevel(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/degree/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async programLevelDeleteByID(uuid: string, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/degree/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getSpecificProgramOutcomeList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/specific-outcomes/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async sendUserFeedBack(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/feedback_save/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateProgralLevel(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/degree/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getProgramLevelList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/degree/list/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getProgramLevelListByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/degree/get_by_id/${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getBloomsTaxonomyList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/blooms_taxonomy_levels/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getBloomsTaxonomy(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/all-blooms-taxonomy-levels/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteBloomsByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/blooms_taxonomy_levels/delete/${uuid}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getObeProgramsList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_programs_for_obe/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },
    //without pagination
    async getProgramOBEList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/all_list_program_for_obe/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async resetDevice(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/reset_student_device/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getSimilarQuestionList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/question/gin/search/?query=${data?.query}&course=${data?.course}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getExamAttedenceTracking(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/get_student_attendance/${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCOSpecificProgrameOutcomeList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/specific_outcomes_for_co/${data}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getExamStudentList(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam_student_get_by_id/${uuid}/?course_id=${data}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getExamStaffList(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam_staff_get_by_id/${uuid}/?course_id=${data}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllRegulationList(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_all_regulations/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllDepartmentList(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_all_departments/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllProgramLevelList(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_all_degrees/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllBloomsLevel(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/all-blooms-taxonomy-levels/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllProgramOutcomes(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/all-program-outcomes/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllCourseOutcomes(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/all-course-outcomes/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAutomatePreview(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_template_json?template_id=${''}&exam_id=${''}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getOverallExamView(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`report_app/generate_reports/?page=1&limit=10&uuid=${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async staffUpload(data: FormData, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/upload/staff/`, 'POST', data, token, true);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async courseUpload(data: FormData, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/upload_course_csv/`, 'POST', data, token, true);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },
    async getConcentrationList(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_all_concentrations`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getQuestionDescriptiveType(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_question_descriptive_type/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async studentAnswerFileUpload(data: FormData, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/pdf_upload_offline_exam/`, 'POST', data, token, true);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async offlineEvaluate(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_offline_results/${uuid}`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async offlineEvaluation(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/offline_question_evaluation/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    // async offlinePdfEvaluation(data: any, token: string): Promise<ResponseType> {
    //     try {
    //         return callApi(`teacher_app/offline_ai_evaluate/`,'GET', data, token);
    //     } catch (error) {
    //         throw new Error(`Error: ${error}`);
    //     }
    // },

    async getUniversityDetail(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_university/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateUniversityDetailByID(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/update_university/${uuid}/`, 'PUT', data, token, true);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateUniversityDetail(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/create_university/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCampusDetail(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/campus/list/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCampusDetailByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/campus/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateCampusDetail(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/campus/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async removeCampusDetailByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/campus/delete/${uuid}`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createCampus(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/campus/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createCollege(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/college/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateCollegeDetail(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/college/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCollegeDetail(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/college/list/`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getCollegeDetailByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/college/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async removeCollegeDetailByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/college/delete/${uuid}`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async programBasedCourse(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_course_for_program/${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async userRoleMapList(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_designation`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async createUserRoleMapList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/course_hierarchy_create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getUniversity(data: any, token: string, method: any): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_course_hierarchy/`, method, data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllCourseHieratchy(data: any, token: string, page: number): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_all_course_hierarchy/?page=${page}&limit=15`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async uploadCsvQuestion(data: FormData, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/upload/question/`, 'POST', data, token, true);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async userDesignationList(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/designations`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async userDesignationGetByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/designations/${uuid}/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async userDesignationCreation(data: { name: string; module_access: { module_id: any; module_name: string | undefined; submodules: any[]; }[]; }, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/designation/create/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updateDesignationByID(uuid: any, data: { name: string; module_access: { module_id: any; module_name: string | undefined; submodules: any[]; }[]; }, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/designations/update/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async userDesignationModulesList(token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/modules_for_user/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteDesignation(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/designations/delete/${id}/`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async levelCreation(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/create_staff_hierarchy/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async levelCreationList(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_staff_hierarchy/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async levelCreationListGetByID(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_staff_hierarchy/${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async updatelevelCreationGetByID(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/update_staff_hierarchy/${uuid}`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async deleteLevel(id: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/delete_staff_hierarchy/${id}`, 'DELETE', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async collegeBasedFaculty(data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/list_staff_for_course/`, 'POST', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async Exam_Bulk_CSV_Or_XLS_Upload(data: FormData, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam/bulk/upload/`, 'POST', data, token, true);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async collegeList(token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/list_all_colleges/`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async MalpracticeAPI(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/user_malpractice/${uuid}/`, 'PUT', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async MalpracticeGetByIdAPI(examID: any, studentID: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/get_student_audit_trail_by_id/?exam_uuid=${examID}&student_uuid=${studentID}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async MalpracticeLog(examID: any, studentID: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/get_audit_trail/?exam_uuid=${examID}&student_uuid=${studentID}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async courseBasedHierarchy(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/get_course_hierarchy/${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async studentAnswerPDFBulkUpload(data: FormData, token: string): Promise<ResponseType> {
        try {
            return callApi(`teacher_app/upload_student_offline_bulk_pdf/`, 'POST', data, token, true);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getExamWiseAllStudentRecord(uuid: any, data: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`admin_app/exam_student_staff_lists/${uuid}`, 'GET', data, token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async getAllBloomslevel(token: string): Promise<ResponseType> {
        try {
            return callApi(`/admin_app/all_domains_for_blooms`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },

    async examBasedStaffList(uuid: any, token: string): Promise<ResponseType> {
        try {
            return callApi(`user_app/staff_lists_exam/${uuid}`, 'GET', '', token);
        } catch (error) {
            throw new Error(`Error: ${error}`);
        }
    },
}