import axios from 'axios';
import { store } from '../redux/store';
import { logout } from '../reducers/userReducer';

const API_URL = process.env.REACT_APP_API || '';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 403) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);

export default api;