import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { FaStarOfLife } from "react-icons/fa6";
import { useLingui } from "@lingui/react/macro";

interface SelectProps {
  options?: any;
  isMulti?: boolean;
  selectAllOption?: boolean;
  selectedOption?: any;
  setSelectedOption?: any;
  labelName?: string;
  placeholder?: string;
  errorMsg?: string;
  filter?: boolean;
  isClearable?: boolean;
  initialSelect?: boolean;
  className?: any;
  tiny?: boolean;
  InputBg?: string;
  InputBorder?: string;
  isCreatable?: boolean;
  customHeight?: string;
  value?: any;
  disabled?: boolean;
  mandatory?: boolean;
}

export const DropdownSelect: React.FC<SelectProps> = ({
  options = [],
  isMulti = false,
  selectAllOption = false,
  selectedOption,
  setSelectedOption,
  labelName,
  placeholder,
  errorMsg,
  filter,
  isClearable,
  initialSelect = false,
  tiny,
  InputBg,
  InputBorder,
  value,
  className,
  customHeight,
  mandatory,
  isCreatable = false,
  disabled = false,
  ...args
}) => {

  const { t } = useLingui();

  const selectRef = useRef<any>(null);
  useEffect(() => {
    if (initialSelect && options.length > 0 && !selectedOption) {
      setSelectedOption([options[0]]);
    }
  }, [initialSelect, options, setSelectedOption, selectedOption]);

  const handleChange = (selected: any) => {
    if (selected.some((option: any) => option?.value === "all")) {
      if (selected.length === options.length + 1) {
        setSelectedOption([]);
      } else {
        const allOptionsExceptSelectAll = options?.filter(
          (option: any) => option.value !== "all"
        );
        setSelectedOption(allOptionsExceptSelectAll);
      }
    } else {
      const selectedWithoutSelectAll = selected?.filter(
        (option: any) => option.value !== "all"
      );
      setSelectedOption(selectedWithoutSelectAll);
    }
  };

  const handleSelectAllClick = () => {
    if (selectedOption?.length === options.length) {
      setSelectedOption([]);
    } else {
      setSelectedOption(options);
    }
  };

  const handleCreate = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };
    // const newOptions = [...options, newOption];
    setSelectedOption([...selectedOption, newOption]);
  };

  const enhancedOptions =
    isMulti && selectAllOption && options.length > 0
      ? [{ value: "all", label: "Select All" }, ...options]
      : options;

  const displayedSelectedOptions =
    isMulti && selectAllOption
      ? selectedOption?.filter((option: any) => option?.value !== "all")
      : selectedOption;

  const customStyles = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      overflow: "auto",
      display: "flex",
      height: customHeight ? customHeight : filter ? "38px" : "42px",
      backgroundColor: disabled ? "#f0f0f0" : InputBg ? InputBg : "white",
      borderColor: state.isFocused
        ? filter
          ? "#6240C8"
          : "none"
        : filter
          ? "#D4D4D8"
          : "#E4E4E7",
      boxShadow: null,
      outline: state.isFocused
        ? filter
          ? "none"
          : " 2px solid #6240C8"
        : null,
      borderRadius: filter ? "8px" : "6px",
      "&:hover": {
        borderColor: "none",
      },
      cursor: disabled ? "not-allowed" : "default",
      fontSize: filter ? "12px" : "16px",
    }),
    menu: (baseStyles: any) => ({
      ...baseStyles,
      zIndex: 9999,
      overflow: "hidden",
      position: "absolute",
    }),
    menuList: (baseStyles: any) => ({
      ...baseStyles,
      paddingTop: 0,
      paddingBottom: 0,
      
    }),
    menuPortal: (baseStyles: any) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      backgroundColor: state.isFocused ? "#E2E8F0" : "white",
      color: "#2D3748",
      "&:active": {
        backgroundColor: "#E2E8F0",
      },
      cursor: state.data.isDisabled ? "not-allowed" : "default",
      opacity: state.data.isDisabled ? 0.5 : 1,
      fontSize: filter ? "12px" : "16px",
    }),
    multiValue: (baseStyles: any) => ({
      ...baseStyles,
      display: "none",
    }),
    input: (baseStyles: any) => ({
      ...baseStyles,
      color: "#38A169",
    }),
    valueContainer: (baseStyles: any) => ({
      ...baseStyles,
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      whiteSpace: "nowrap",
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      whiteSpace: "nowrap",
    }),
    indicatorContainer: (baseStyles: any) =>
      tiny || filter ? { display: "none" } : baseStyles,
    indicatorSeparator: (baseStyles: any) =>
      tiny || filter ? { display: "none" } : baseStyles,

    clearIndicator: (baseStyles: any) => ({
      ...baseStyles,
      padding: filter ? "0px" : baseStyles.padding, 
      svg: {
        height: filter ? "14px" : "20px", 
        width: filter ? "14px" : "20px",  
      },
    }),
    dropdownIndicator: (baseStyles: any) => ({
      ...baseStyles,
      paddingLeft: filter ? "2px" : baseStyles.paddingLeft,
      paddingRight: filter ? "4px" : baseStyles.paddingRight,
      svg: {
        height: filter ? "14px" : "20px", // Adjust SVG height
        width: filter ? "14px" : "20px",  // Adjust SVG width
      },
    }),
  };

  const Option = (props: any) => {
    const handleCheckboxChange = () => {
      if (props.data.value === "all") {
        handleSelectAllClick();
      } else {
        props.selectOption(props.data);
      }
    };

    return (
      <components.Option {...props}>
        <div className="flex gap-2 items-center">
          {isMulti && (
            <input
              type="checkbox"
              checked={
                props.data.value === "all"
                  ? selectedOption?.length === options.length
                  : props.isSelected
              }
              onChange={handleCheckboxChange}
              className="accent-purple-500"
              disabled={props.data.isDisabled}
            />
          )}
          <label
            className="ml-1"
            style={{
              overflowWrap:"anywhere",
              opacity: props.data.isDisabled ? 0.5 : 1,
              cursor: props.data.isDisabled ? "not-allowed" : "default",
            }}
          >
            {props.label}
          </label>
        </div>
      </components.Option>
    );
  };

  const MultiValue = (props: any) => (
    <div className="flex">
      <span className="truncate">{props.data.label}</span>
      {props.selectProps.value.indexOf(props.data) !==
        props.selectProps.value.length - 1 && <span className="mr-1">,</span>}
    </div>
  );

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  return (
    <div ref={selectRef}>
      {labelName && (
        <p className="text-xs  text-GreatifyNeutral-500 font-medium uppercase mb-2 flex justify-start items-start">
          {labelName}
          {mandatory && <FaStarOfLife className="text-red-500 text-[5px]" />}
        </p>
      )}
      <SelectComponent
        id={`dropdown-${labelName || "select-component"}`}
        styles={customStyles}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        value={displayedSelectedOptions}
        onChange={isMulti && selectAllOption ? handleChange : setSelectedOption}
        options={enhancedOptions}
        placeholder={initialSelect ? "" : placeholder || t`Select...`}
        components={{ Option, MultiValue, MultiValueRemove: () => null }}
        isClearable={isClearable}
        onCreateOption={handleCreate}
        menuPosition="fixed"
        menuPortalTarget={document.body}
        isDisabled={disabled}
        {...args}
      />
      {errorMsg && (
        <p className="text-red-500 mt-1 text-sm">
          {(!selectedOption || selectedOption?.length === 0) && errorMsg}
        </p>
      )}
    </div>
  );
};
