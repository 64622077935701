import React from "react";

export const QuestionPickLoader = () => {
    return (
      <React.Fragment>
      <div className="px-1 py-1 mb-2 flex justify-between items-center w-full  border border-GreatifyNeutral-200 rounded-lg">
        <div className="animate-pulse h-[30px] w-[70%] bg-gray-200 rounded-md "></div>
        <div className="animate-pulse h-[30px] w-[10%] bg-gray-200 rounded-md"></div>
      </div>
      <div className="px-1 py-1 mb-2 flex justify-between items-center w-full  border border-GreatifyNeutral-200 rounded-lg">
      <div className="animate-pulse h-[30px] w-[70%] bg-gray-200 rounded-md "></div>
      <div className="animate-pulse h-[30px] w-[10%] bg-gray-200 rounded-md"></div>
    </div>
    </React.Fragment>
    );
  };