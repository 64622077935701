import { combineReducers } from '@reduxjs/toolkit';
// import userReducer from './userReducer'; 
import userReducer, { UserState } from './userReducer';

// import apiServiceReducer from './apiServiceReducer';

interface RootState {
  root: any;
  user: UserState;
}

const rootReducer = combineReducers({
  user: userReducer,
  // apiService: apiServiceReducer,
});

// export type RootState = ReturnType<typeof rootReducer>;
export type { RootState };
export default rootReducer;
