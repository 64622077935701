// posthog-rotation.js
import posthog from 'posthog-js'

class PostHogKeyRotation {
  constructor(keys = [], options = {}) {
    this.keys = keys
    this.currentKeyIndex = 0
    this.retryDelay = options.retryDelay || 5000
    this.maxRetries = options.maxRetries || 3
    this.initialized = false
    this.retryCount = 0
  }

  getDistinctId() {
    // Try to get existing distinct_id from PostHog first
    let distinctId = posthog.get_distinct_id()
    
    // If no distinct_id in PostHog, check localStorage
    if (!distinctId) {
      distinctId = localStorage.getItem('posthog_distinct_id')
    }
    
    // If still no distinct_id, generate a new one
    if (!distinctId) {
      distinctId = 'user_' + Math.random().toString(36).substr(2, 9)
      localStorage.setItem('posthog_distinct_id', distinctId)
    }
    
    return distinctId
  }

  async init() {
    if (this.initialized) return
    
    try {
      await this.initializeWithCurrentKey()
    } catch (error) {
      console.error('PostHog initialization failed:', error)
      if (error.message.includes('Failed to initialize with key')) {
        await this.rotateAndRetry()
      } else {
        throw error // Re-throw unexpected errors
      }
    }
  }

  async initializeWithCurrentKey() {
    const currentKey = this.keys[this.currentKeyIndex]
    
    if (!currentKey) {
      throw new Error('No valid PostHog API keys available')
    }

    try {
      const distinctId = this.getDistinctId()

      posthog.init(currentKey, {
        api_host: process.env.REACT_APP_POSTHOG_HOST || 'https://app.posthog.com',
        loaded: (posthog) => {
          this.initialized = true
          this.retryCount = 0
          
          // Ensure distinct_id is set after initialization
          if (distinctId !== posthog.get_distinct_id()) {
            posthog.identify(distinctId)
          }
        },
        capture_pageview: true,
        persistence: 'localStorage',
        bootstrap: {
          distinctId: distinctId,
          isIdentified: true
        }
      })

      // Test the connection
      await this.testConnection()

    } catch (error) {
      console.error('PostHog initialization error:', error)
      throw new Error(`Failed to initialize with key ${this.currentKeyIndex + 1}: ${error.message}`)
    }
  }

  async testConnection() {
    return new Promise((resolve, reject) => {
      const distinctId = this.getDistinctId()
      
      // Simple test event to verify connection
      posthog.capture('test_connection', {
        timestamp: new Date().toISOString(),
        distinct_id: distinctId
      }, {
        callback: () => resolve(),
        timeout: 3000
      })

      setTimeout(() => reject(new Error('Connection test timeout')), 3000)
    })
  }

  rotateAndRetry() {
    this.retryCount++
    
    if (this.retryCount > this.maxRetries) {
      console.error('Max retries exceeded for PostHog initialization')
      return
    }

    // Store current distinct_id before rotation
    const distinctId = this.getDistinctId()

    // Rotate to next key
    this.currentKeyIndex = (this.currentKeyIndex + 1) % this.keys.length

    // Retry initialization after delay
    setTimeout(() => {
      this.init()
      
      // Ensure distinct_id persists after rotation
      if (distinctId !== posthog.get_distinct_id()) {
        posthog.identify(distinctId)
      }
    }, this.retryDelay)
  }

  rotate() {
    const distinctId = this.getDistinctId()
    this.currentKeyIndex = (this.currentKeyIndex + 1) % this.keys.length
    
    const result = this.init()
    
    // Ensure distinct_id persists after manual rotation
    if (distinctId !== posthog.get_distinct_id()) {
      posthog.identify(distinctId)
    }
    
    return result
  }

  getCurrentKeyInfo() {
    return {
      keyIndex: this.currentKeyIndex,
      initialized: this.initialized,
      retryCount: this.retryCount,
      distinctId: this.getDistinctId()
    }
  }
}

// Usage example
const initPostHogWithRotation = () => {
  const keys = [
    process.env.REACT_APP_POSTHOG_KEY,
    process.env.REACT_APP_POSTHOG_KEY_SECONDARY,
    process.env.REACT_APP_POSTHOG_KEY_FALLBACK
  ].filter(Boolean) // Remove any undefined/null keys
  
  const rotationManager = new PostHogKeyRotation(keys, {
    retryDelay: 5000,
    maxRetries: 3
  })

  return rotationManager
}

export default initPostHogWithRotation