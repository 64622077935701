import { configureStore, ThunkMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/reducer';
import {thunk} from 'redux-thunk';

const migrations = {
  0: (state: any) => {
    return {
      ...state,
      user: {
        ...(state?.user || {}),
        subdomain: undefined,
      },
    }
  },
  1: (state: any) => {
    return {
      device: state.device,
      _persist: state._persist
    }
  }
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  migrate: createMigrate(migrations, { debug: false }),
  debug: process.env.NODE_ENV === 'development' ? true : false,
  version: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer : persistedReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//     serializableCheck : false,
//   }),
// });

import apiService from "./api";

export const store = configureStore({
  reducer: {
    user: persistedReducer,
    [apiService.reducerPath]: apiService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE','persist/PURGE'],
      },
    }).concat(thunk as ThunkMiddleware, apiService.middleware),
});

export const persistor = persistStore(store);
persistor.subscribe(() => {
    const _sealed = true; 
    if (!_sealed) {
      persistor.persist();
    }
});
export default store;